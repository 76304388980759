import React, { Component } from 'react';
import firebase from './firebase';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList,
} from 'recharts';
const renderCustomizedLabel = (props) => {
    const {
        x, y, width, height, value,
    } = props;
    const radius = 10;

    return (
        <g>
            {/* <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" /> */}
            <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
                {value.split(' ')[1]}
            </text>
        </g>
    );
};

export default class LiveUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            totalLiveUsers: 0,
            websiteLiveUsers: 0,
            webinarHallLiveUsers: 0,
            exteriorLiveUsers: 0,
            lobbyLiveUsers: 0,
            displayGalleryLiveUsers: 0,
            archiveGalleryLiveUsers: 0,
            countryRoomLiveUsers: 0,
            facultyRoomLiveUsers: 0,
            breakTimeLiveUsers: 0,
            chartData: [],
        }
        this.getActiveUsers = this.getActiveUsers.bind(this);
    }

    componentDidMount() {
        this.getActiveUsers('MEDEX2022LiveUsers', 1)
    }

    /**
     * 
     * @param {Collection Name to Check} collectionName 
     * @param {Last Active User Threshold Time} timeInMin 
     * This will give active users for current collection name for
     * respective time to asked for.
     */
    getActiveUsers(collectionName, timeInMin) {
        let currentTime = new Date().getTime();
        let timeToWatch = currentTime - (timeInMin * 60000);
        // let db = firebase.firestore();
        // let liveQuery = db.collection(collectionName).where("endTime", '>=', timeToWatch);
        // let self = this;
        // liveQuery.onSnapshot(function (querySnapshot) {
        //     var allUsers = [], websiteUsers = [];
        //     var webinarUsers = [], lobbyUsers = [], exteriorUsers = [], breakTimeUsers = [],
        //         displayUsers = [], archiveUsers = [], countryUsers = [], facultyRoomUsers = [];
        //     querySnapshot.forEach(function (doc) {

        //         if (doc.data().roomName !== 'website') {

        //             allUsers.push(doc.data());

        //             if (doc.data().roomName === 'medex-faculty') {
        //                 facultyRoomUsers.push(doc.data())
        //             } else if (doc.data().roomName === 'webinarHall') {
        //                 webinarUsers.push(doc.data())
        //             } else if (doc.data().roomName === 'exterior') {
        //                 exteriorUsers.push(doc.data())
        //             } else if (doc.data().roomName === 'entrance') {
        //                 lobbyUsers.push(doc.data())
        //             } else if (doc.data().roomName === 'entertainmentZone') {
        //                 breakTimeUsers.push(doc.data())
        //             } else if (doc.data().roomName.startsWith('archive')) {
        //                 archiveUsers.push(doc.data())
        //             } else if (doc.data().roomName.startsWith('display')) {
        //                 displayUsers.push(doc.data())
        //             } else if (doc.data().roomName.startsWith('country')) {
        //                 countryUsers.push(doc.data())
        //             }
        //         } else {
        //             websiteUsers.push(doc.data())
        //         }
        //     });
        //     let data = [
        //         {
        //             name: 'Total',
        //             Total: allUsers.length,
        //             webinarName: 'Webinar',
        //             Webinar: webinarUsers.length,
        //             lobbyName: 'Reception',
        //             Lobby: lobbyUsers.length,
        //             exteriorName: 'Exterior',
        //             Exterior: exteriorUsers.length,
        //             facultyName: 'Faculty',
        //             Faculty: facultyRoomUsers.length,
        //             displayName: 'Display',
        //             Display: displayUsers.length,
        //             archiveName: 'Archive',
        //             Archive: archiveUsers.length,
        //             countryName: 'Country',
        //             Country: countryUsers.length,
        //             breakName: 'Break Time',
        //             BreakTime: breakTimeUsers.length,
        //         },

        //     ]
        //     self.setState({
        //         chartData: data,
        //         totalLiveUsers: allUsers.length,
        //         websiteLiveUsers: websiteUsers.length,
        //         lobbyLiveUsers: lobbyUsers.length,
        //         breakTimeLiveUsers: breakTimeUsers.length,
        //         webinarHallLiveUsers: webinarUsers.length,
        //         exteriorLiveUsers: exteriorUsers.length,
        //         facultyRoomLiveUsers: facultyRoomUsers.length,
        //         displayGalleryLiveUsers: displayUsers.length,
        //         archiveGalleryLiveUsers: archiveUsers.length,
        //         countryRoomLiveUsers: countryUsers.length
        //     });


        // });

        let snapShotPromise = new Promise((resolve, reject) => {
            let liveQuery = firebase.firestore().collection(collectionName).where("endTime", '>=', timeToWatch)
            liveQuery.onSnapshot(function (querySnapshot) {
                let allData = [];

                querySnapshot.forEach(function (doc) {
                    let currentData = doc.data()
                    currentData.id = doc.id
                    allData.push(currentData)
                })
                resolve(allData)
            })

        });
        snapShotPromise.then(result => {
            let existingData = []
            console.warn('Successful', result)
            for (let i = 0; i < result.length; i++) {
                if (this.isNewUser(result[i].email, existingData)) {
                    existingData.push(result[i])
                }

            }
            this.setDataInChart(existingData)

        })
    }

    isNewUser = (userEmailToCheck, existingUsers) => {
        let status = true;
        for (let i = 0; i < existingUsers.length; i++) {
            if (userEmailToCheck === existingUsers[i].email) {
                status = false;
                break;
            }
        }
        return status;
    }

    setDataInChart = (finalData) => {
        console.warn('final data is ::',finalData)
        var allUsers = [], websiteUsers = [];
        var webinarUsers = [], lobbyUsers = [], exteriorUsers = [], breakTimeUsers = [],
            displayUsers = [], archiveUsers = [], countryUsers = [], facultyRoomUsers = [];
        finalData.forEach(item => {
            if (item.roomName !== 'website') {

                allUsers.push(item);

                if (item.roomName === 'medex-faculty') {
                    facultyRoomUsers.push(item)
                } else if (item.roomName === 'webinarHall') {
                    webinarUsers.push(item)
                } else if (item.roomName === 'exterior') {
                    exteriorUsers.push(item)
                } else if (item.roomName === 'entrance') {
                    lobbyUsers.push(item)
                } else if (item.roomName === 'entertainmentZone') {
                    breakTimeUsers.push(item)
                } else if (item.roomName.startsWith('archive')) {
                    archiveUsers.push(item)
                } else if (item.roomName.startsWith('display')) {
                    displayUsers.push(item)
                } else if (item.roomName.startsWith('country')) {
                    countryUsers.push(item)
                }
            } else {
                websiteUsers.push(item)
            }
        })

        let data = [
                    {
                        name: 'Total',
                        Total: allUsers.length,
                        webinarName: 'Webinar',
                        Webinar: webinarUsers.length,
                        lobbyName: 'Reception',
                        Lobby: lobbyUsers.length,
                        exteriorName: 'Exterior',
                        Exterior: exteriorUsers.length,
                        facultyName: 'Faculty',
                        Faculty: facultyRoomUsers.length,
                        displayName: 'Display',
                        Display: displayUsers.length,
                        archiveName: 'Archive',
                        Archive: archiveUsers.length,
                        countryName: 'Country',
                        Country: countryUsers.length,
                        breakName: 'Break Time',
                        BreakTime: breakTimeUsers.length,
                    },
    
                ]
                this.setState({
                    chartData: data,
                    totalLiveUsers: allUsers.length,
                    websiteLiveUsers: websiteUsers.length,
                    lobbyLiveUsers: lobbyUsers.length,
                    breakTimeLiveUsers: breakTimeUsers.length,
                    webinarHallLiveUsers: webinarUsers.length,
                    exteriorLiveUsers: exteriorUsers.length,
                    facultyRoomLiveUsers: facultyRoomUsers.length,
                    displayGalleryLiveUsers: displayUsers.length,
                    archiveGalleryLiveUsers: archiveUsers.length,
                    countryRoomLiveUsers: countryUsers.length
                });

    }

    render() {
        return (
            <div>
                <div>
                    {/* 
                    Webinar : {this.state.webinarHallLiveUsers}
                    <br />
                    Exterior : {this.state.exteriorLiveUsers}
                    <br />
                    Reception Lobby : {this.state.lobbyLiveUsers}
                    <br />
                    MEDEX Faculty Room : {this.state.facultyRoomLiveUsers}
                    <br />
                    Display Gallery : {this.state.displayGalleryLiveUsers}
                    <br />
                    Archive Gallery : {this.state.archiveGalleryLiveUsers}
                    <br />
                    Country Experience : {this.state.countryRoomLiveUsers}
                    <br />
                    Break Time Zone : {this.state.breakTimeLiveUsers}
                    <br /> */}
                    <br />
                    Total : {this.state.totalLiveUsers}
                    <br />
                </div>
                <div className="container-fluid">
                    <div className="row marginchart">
                        <div className="col-12 col-md-6 col-lg-6 marginchartcol" style={{ width: '400px', height: '300px' }}>
                            <BarChart
                                width={1000}
                                height={350}
                                data={this.state.chartData}
                                // data={data}
                                margin={{
                                    top: 5, right: 30, left: 20, bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="Total" fill="#CD6057" minPointSize={5} >
                                    <LabelList dataKey="name" content={renderCustomizedLabel} />
                                </Bar>
                                <Bar dataKey="Webinar" fill="#AA6E9E" minPointSize={5} >
                                    <LabelList dataKey="webinarName" content={renderCustomizedLabel} />
                                </Bar>
                                <Bar dataKey="Lobby" fill="#3E8AA9" minPointSize={5} >
                                    <LabelList dataKey="lobbyName" content={renderCustomizedLabel} />
                                </Bar>
                                {/* <Bar dataKey="Exterior" fill="#2E916F" minPointSize={5} >
                                    <LabelList dataKey="exteriorName" content={renderCustomizedLabel} />
                                </Bar> */}
                                <Bar dataKey="Faculty" fill="#828637" minPointSize={5} >
                                    <LabelList dataKey="facultyName" content={renderCustomizedLabel} />
                                </Bar>
                                <Bar dataKey="Display" fill="#B96F49" minPointSize={5} >
                                    <LabelList dataKey="displayName" content={renderCustomizedLabel} />
                                </Bar>
                                <Bar dataKey="Archive" fill="#AA6E9E" minPointSize={5} >
                                    <LabelList dataKey="archiveName" content={renderCustomizedLabel} />
                                </Bar>
                                <Bar dataKey="Country" fill="#3E8AA9" minPointSize={5} >
                                    <LabelList dataKey="countryName" content={renderCustomizedLabel} />
                                </Bar>
                                <Bar dataKey="BreakTime" fill="#B96F49" minPointSize={5} >
                                    <LabelList dataKey="breakName" content={renderCustomizedLabel} />
                                </Bar>
                                {/* <Bar dataKey="Webinar" fill="#AA6E9E" minPointSize={10} />
                                <Bar dataKey="Lobby" fill="#3E8AA9" minPointSize={10} />

                                <Bar dataKey="Exterior" fill="#2E916F" minPointSize={10} />
                                <Bar dataKey="Faculty" fill="#828637" minPointSize={10} />
                                <Bar dataKey="Display" fill="#B96F49" minPointSize={10} />
                                <Bar dataKey="Archive" fill="#AA6E9E" minPointSize={10} />
                                <Bar dataKey="Country" fill="#3E8AA9" minPointSize={10} /> */}
                            </BarChart>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
