/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Switch, withRouter, BrowserRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import AdminRegistration from './AdminRegistration';
import MainDash from './main-dash';
// import UserList from './userlist';
// import UserLogs from './userlogs';
// import ProjectVisit from './projectvisit';
// import Spendtime from './Spendtime';
// import ImageClick from './ImageClick';
// import SceneVisit from './scenevisit';
// import Charts from './charts';
// import PieCharts from './piecharts';
// import lablePiechart from './lablePiechart';
class RouteContainer extends React.Component {
  constructor() {
    super()
    this.state = {
    }
  }

  render() {
    return (
      <TransitionGroup>
        <CSSTransition
          key={this.props.location.key}
          timeout={{ enter: 600, exit: 600 }}
          classNames="fade"
          unmountOnExit
        >
          <Switch location={this.props.location}>
            <Route exact path="/" component={MainDash} />
            
            {/* <Route exact path="/dashboard/projects" component={MainDash}/> */}

            {/* <Route exact path="/dashboard/default" component={MainDash}/> */}
            {/* <Route exact path="userlist" component={MainDash}/> */}
            <Route path="/userlist" component={MainDash} />
            <Route path="/live" component={MainDash} />
            <Route path="/videoAnalytics" component={MainDash} />
            <Route path="/WebinarHall" component={MainDash} />
            <Route path="/WebinarHallExport" component={MainDash} />
            <Route path="/Lobbylite" component={MainDash} />
            <Route path="/BreakoutRoom" component={MainDash} />
            <Route path="/ChatRoom" component={MainDash} />
            {/* <Route path="/datausage" component={MainDash} /> */}
            <Route path="/DisplayGallery" component={MainDash} />
            <Route path="/ArchiveGallery" component={MainDash} />
            <Route path="/EntertainmentZone" component={MainDash} />
            <Route path="/VideoClick" component={MainDash} />
            <Route path="/imageclick" component={MainDash} />
            {/* <Route path="/piechart" component={MainDash} /> */}
            <Route path="/barchart" component={MainDash} />
            <Route path="/Lobby" component={MainDash} />
            <Route path="/adminreg" component={MainDash} />
            <Route path="/medexFaculty" component={MainDash} />
            <Route path="/countryExperience" component={MainDash} />
            <Route path="/country1" component={MainDash} />
            <Route path="/country2" component={MainDash} />
            <Route path="/country3" component={MainDash} />
            <Route path="/country4" component={MainDash} />
            <Route path="/country5" component={MainDash} />
            <Route path="/country6" component={MainDash} />
            <Route path="/country7" component={MainDash} />
            <Route path="/country8" component={MainDash} />
            <Route path="/display_Gallery" component={MainDash} />
            <Route path="/archive_Gallery" component={MainDash} />
            <Route path="/archiveExport" component={MainDash} />
            <Route path="/displayGallaryExport" component={MainDash} />
            <Route path="/combineExcel" component={MainDash} />
            <Route path="/combineExcel1" component={MainDash} />
            <Route path="/archive1" component={MainDash} />
            <Route path="/archive2" component={MainDash} />
            <Route path="/archive3" component={MainDash} />
            <Route path="/archive4" component={MainDash} />
            <Route path="/archive5" component={MainDash} />
            <Route path="/archive6" component={MainDash} />
            <Route path="/archive7" component={MainDash} />
            <Route path="/archive8" component={MainDash} />
            <Route path="/archive9" component={MainDash} />
            <Route path="/archive10" component={MainDash} />
            <Route path="/archive11" component={MainDash} />
            <Route path="/break-time-zone" component={MainDash} />

            {/* <Route path="/dashboard/scenevisit" component={Charts} /> */}
            {/* <Route path="/dashboard/scenevisit" component={PieCharts} /> */}
           
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}

export default withRouter(RouteContainer);
