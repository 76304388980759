import { Box, Button, Chip, FormControl, Grid, IconButton, InputBase, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, CircularProgress } from '@material-ui/core';
import { ExpandMore, GridOnSharp } from '@material-ui/icons';
import React, { Component } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import firebase from './firebase';
import { videoList } from './videoList';


const HeaderInput = styled(InputBase)(({ theme }) => ({
    root: {
        "& .MuiFormLabel-root-MuiInputLabel-root": {
            fontFamily: "var(--font-family)",
        },
        "& .MuiInputLabel-shrink": {
            fontFamily: "var(--font-family)",
        },
        fontFamily: "var(--font-family)",
    },
    "label + &": {
        fontFamily: "var(--font-family)",
        marginTop: "10px",
        borderRadius: 5,
    },
    "& .MuiInputBase-input": {
        borderRadius: 5,
        position: "relative",
        backgroundColor: "transparent",
        border: "1px solid #000",
        fontSize: "var(--font-size)",
        padding: "7px 26px 7px 15px",
        fontFamily: "var(--font-family)",
    },
    "& .MuiSelect-icon": {
        borderRadius: "50%",
        background: "#ed9254a3",
        "&:hover": {
            backgroundColor: "#ed9254",
        },
    },
    "& .MuiFormLabel-root-MuiInputLabel-root": {
        fontFamily: "var(--font-family)",
    },
}));

const LinkButton = styled(Button)(({ theme }) => ({
    background: "none",
    textDecoration: "underline",
    textTransform: "capitalize",
    color: "#AFAFAF",
    fontFamily: "var(--font-family)",
    fontWeight: 600,
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "transparent",
        color: "#000",
    },
}));

export default class VideoAnalytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            videoName: "M-01",
            videoList: videoList,
            selectedVideo: videoList[0],
            usersData: [],
            page: 0,
            rowsPerPage: 10,
            videoBaseLink: "https://pptvr-images.s3.ap-south-1.amazonaws.com/image/public/image2d/medex-2022/2024-updated/",
            isDataLoading: false
        }
        this.getVideoUsers = this.getVideoUsers.bind(this);
    }

    componentDidMount() {
        this.getVideoUsers('MEDEX2022VideoWatch')
    }

    getVideoUsers(collectionName) {
        this.setState({
            isDataLoading: true
        })
        console.log("this.state.videoName", this.state.videoName)
        let snapShotPromise = new Promise((resolve, reject) => {
            let liveQuery = firebase.firestore().collection(collectionName).where("videoName", '==', this.state.videoName)
            liveQuery.onSnapshot(function (querySnapshot) {
                let allData = [];

                querySnapshot.forEach(function (doc) {
                    let currentData = doc.data()
                    currentData.id = doc.id
                    allData.push(currentData)
                })
                resolve(allData)
            })

        });
        snapShotPromise.then(result => {
            console.log("All selected video data", result);

            // group the data based on the user name
            let users = Object.entries(groupBy(result, "email"));
            console.log("users", users);
            let userData = [];
            users.map((user, uIndex) => {
                let watchArray = [];
                let videoData = [];
                user[1].map((videoHistory, vIndex) => {
                    console.log("videoHistory", videoHistory);
                    videoData.push({
                        date: new Date(videoHistory.entryOn).toLocaleTimeString("en-IN"),
                        duration: videoHistory.watchedDuration,
                        overallDuration: secondsToTime(videoHistory.otherDetails.overallSpentTime),
                        overallPauseDuration: secondsToTime(videoHistory.otherDetails.overallPauseTime),
                        pauseCount: videoHistory.otherDetails.pauseCount,
                        timeRange: videoHistory.otherDetails.timeRange
                    })
                    watchArray = [...watchArray, ...videoHistory.otherDetails.watchedFrames];
                    console.log("watchArray", watchArray);
                    if (user[1].length - 1 === vIndex) {
                        // Get the unique data of watchArray
                        let uData = [...new Set(watchArray)];
                        let uniqueData = uData.sort((a, b) => a - b);
                        console.log("watchArray uniqueData", uniqueData);
                        let timeRange = [];
                        let rangeValue = "";
                        let firstIndex = 0;
                        uniqueData.map((time, index) => {
                            if (index === 0) {
                                rangeValue = `${secondsToTime(uniqueData[firstIndex])} - ${secondsToTime(uniqueData[index])}`
                            } else {
                                if (parseInt(uniqueData[index - 1]) + 1 === uniqueData[index]) {
                                    rangeValue = `${secondsToTime(uniqueData[firstIndex])} - ${secondsToTime(uniqueData[index])}`
                                } else {
                                    firstIndex = index;
                                    timeRange.push(rangeValue);
                                    rangeValue = `${secondsToTime(uniqueData[index])} - ${secondsToTime(uniqueData[index])}`
                                }
                            }
                            if (uniqueData.length - 1 === index) {
                                timeRange.push(rangeValue);
                                userData.push({
                                    totalDuration: secondsToTime(uniqueData.length - 1),
                                    totalTimeRange: timeRange,
                                    name: videoHistory.name,
                                    email: videoHistory.email,
                                    contact: videoHistory.mobileNumber,
                                    country: videoHistory.country,
                                    completion: ((uniqueData.length - 1) / this.state.selectedVideo.videoDuration) * 100,
                                    videoData: videoData
                                })
                                if (users.length - 1 === uIndex) {
                                    console.log("users data", userData);
                                    this.setState({
                                        usersData: userData,
                                        isDataLoading: false
                                    })
                                }
                            }
                        })
                        if (uniqueData.length === 0) {
                            userData.push({
                                totalDuration: secondsToTime(uniqueData.length),
                                totalTimeRange: timeRange,
                                name: videoHistory.name,
                                email: videoHistory.email,
                                contact: videoHistory.mobileNumber,
                                country: videoHistory.country,
                                completion: (uniqueData.length / this.state.selectedVideo.videoDuration) * 100,
                                videoData: videoData
                            })
                            if (users.length - 1 === uIndex) {
                                this.setState({
                                    usersData: userData,
                                    isDataLoading: false
                                })
                            }
                        }
                    }
                });
            })
            if (users.length === 0) {
                this.setState({
                    usersData: [],
                    isDataLoading: false
                })
            }
        })
    }


    render() {
        const { videoList, selectedVideo, videoName, usersData, videoBaseLink, isDataLoading, page, rowsPerPage } = this.state;
        return (
            <Box className="container-fluid">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography
                            marginBottom={2}
                            variant="h5"
                            style={{
                                fontWeight: 600,
                                fontSize: 25,
                                fontFamily: ["Montserrat", "sans-serif"].join(","),
                                // color: "#000",
                                textAlign: "center",
                            }}
                        >Video Analytics</Typography>
                    </Grid>
                    <Grid item xs={8} md={9} lg={9}></Grid>
                    <Grid item xs={4} md={3} lg={3}>
                        <FormControl fullWidth>
                            <Select
                                labelId='videoSelection'
                                id='video'
                                fullWidth
                                onChange={e => {
                                    this.setState({
                                        videoName: (e.target.value).name,
                                        selectedVideo: e.target.value
                                    }, () => {
                                        console.log((e.target.value).name, "videoName", videoName);
                                        this.getVideoUsers('MEDEX2022VideoWatch', videoName);
                                    })
                                }}
                                value={selectedVideo}
                                input={<HeaderInput />}
                                IconComponent={ExpandMore}
                            >
                                <MenuItem key='-1' value='-1' disabled>
                                    <strong>
                                        <em>{"Select Video"}</em>
                                    </strong>
                                </MenuItem>
                                {videoList.map((video, index) => (
                                    <MenuItem
                                        key={index}
                                        value={video}
                                        style={{
                                            fontWeight: 500,
                                            color: '#303030',
                                            textOverflow: 'ellipsis',
                                            width: '100%',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <Typography
                                            variant='body1'
                                            style={{
                                                fontWeight: 500,
                                                color: '#303030',
                                                textOverflow: 'ellipsis',
                                                width: '100%',
                                                overflow: 'hidden'
                                            }}
                                        >
                                            {video.name.replace("_"," ")}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={9} lg={9}>
                        <Box style={{display: "flex", alignItems: "center"}}>
                            <Typography
                                marginBottom={2}
                                variant="body2"
                                style={{
                                    fontWeight: 600,
                                    fontFamily: ["Montserrat", "sans-serif"].join(","),
                                    // color: "#000",
                                    textAlign: "start",
                                }}
                            >{selectedVideo.name} - {selectedVideo.videoName}</Typography>
                            <LinkButton onClick={() => {
                                window.open(`${videoBaseLink}${selectedVideo.name}-c.mp4`, "_blank")
                            }}>Watch video</LinkButton>
                        </Box>
                        <Typography
                            marginBottom={2}
                            variant="body2"
                            style={{
                                fontWeight: 600,
                                fontFamily: ["Montserrat", "sans-serif"].join(","),
                                // color: "#000",
                                textAlign: "start",
                            }}
                        >{selectedVideo.speakerName}</Typography>
                        <Typography
                            marginBottom={2}
                            variant="caption"
                            style={{
                                fontWeight: 600,
                                fontFamily: ["Montserrat", "sans-serif"].join(","),
                                // color: "#000",
                                textAlign: "start",
                            }}
                        >{secondsToTime(selectedVideo.videoDuration)}</Typography>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} style={{ display: "flex", justifyContent: "end" }}>
                        {/* <IconButton className={"watchVideo"}>
                            <GridOnSharp />
                            Download
                        </IconButton> */}
                        <ReactHtmlTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button  downloadbtn"
                            table="table-to-xls"
                            filename="MEDEX 2022 video analytics"
                            sheet={`Video module: ${selectedVideo.name}`}
                            buttonText={`Download`} 
                            />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>

                        <TableContainer component={Paper}>
                            <Table id="table-to-xls">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{`Sr. No`}</TableCell>
                                        <TableCell>{`Name`}</TableCell>
                                        <TableCell>{`Email`}</TableCell>
                                        <TableCell>{`Contact`}</TableCell>
                                        <TableCell>{`Country`}</TableCell>
                                        <TableCell>{`Session count`}</TableCell>
                                        <TableCell>{`Video completion (%)`}</TableCell>
                                        <TableCell>{`Watched video range`}</TableCell>
                                        <TableCell>{`Overall time spent`}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {!isDataLoading ?
                                        usersData.length > 0 ? usersData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell>{i + 1}</TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {user.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {user.email}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {user.contact}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {user.country}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {user.videoData.length}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {parseFloat(user.completion).toFixed(2)}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.totalTimeRange.map((value) => (
                                                            <Chip key={value} label={value} className="chips" />
                                                        ))}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography
                                                            variant="caption"
                                                            style={{
                                                                fontFamily: "Montserrat, sans-serif",
                                                                width: "100%",
                                                                margin: "auto"
                                                            }}
                                                        >
                                                            {user.totalDuration}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }) :
                                            <TableRow>
                                                <TableCell colSpan={9} style={{ textAlign: "center" }}>
                                                    <Typography
                                                        variant="caption"
                                                        style={{
                                                            fontFamily: "Montserrat, sans-serif",
                                                            width: "100%",
                                                            margin: "auto"
                                                        }}
                                                    >
                                                        {'No Data available'}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        :
                                        (
                                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                                <CircularProgress />
                                            </Box>
                                        )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={usersData.length}
                            page={page}
                            onPageChange={(event, newPage) => { this.setState({ page: newPage }) }}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={(event) => {
                                this.setState({ rowsPerPage: +event.target.value, page: 0 });
                            }}
                            sx={{
                                "& .MuiTablePagination-toolbar": {
                                    padding: 0
                                },
                            }}
                            SelectProps={{
                                style: {
                                    margin: 0,
                                    padding: 0
                                },
                            }}
                        />
                    </Grid>

                </Grid>
            </Box>
            // <div>
            // </div>
        )
    }
}
function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);

        return acc;
    }, []);
}

function secondsToTime(seconds) {
    return new Date(seconds * 1000).toISOString().substring(14, 19)
}