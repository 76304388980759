/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from "react-router-dom";
import { Navbar, Form, FormControl, Dropdown } from 'react-bootstrap';
import { faSearch, faBell, faEnvelope, faUserAstronaut, faChartPie, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UKflag from '../assets/images/uk-flag.png';
import SPflag from '../assets/images/spanish-flag.png';
import TRflag from '../assets/images/turkish-flag.png';
import UserAvatar from '../assets/images/user-profile.jpeg';
import dashboard from '../assets/images/dash board.png';
import signout from '../assets/images/log out.png';


import '../assets/styles/navbar.css';

import firebase from '../firebase';
let currentComponent;
export default class NavigationBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usernameLocal: '',
        }

        this.Clicklogout = this.Clicklogout.bind(this);
    }

    componentDidMount() {
        currentComponent = this;
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const userEmail = user.email
                currentComponent.setState({
                    usernameLocal: userEmail
                })
            } else {
                this.context.logout(false);
            }
        });
    }

    Clicklogout = (e) => {
        e.preventDefault();
        localStorage.setItem('project-id', '');
        localStorage.setItem('user-name', '');
        localStorage.setItem('user-role', '');
        const auth = firebase.auth();
        auth.signOut().then((result) => {
            window.location.href = "/";
        }).catch((error) => {
        });
    }

    render() {
        const { usernameLocal } = this.state
        const userName = localStorage.getItem('user-name');
        // console.log("My EMAIL :: ", userName)
        return (
            <>
                <Navbar expand="lg" className="navbar-prop">

                    {/* <Navbar.Brand className="navbar-brand-prop" href="#home">Search </Navbar.Brand>
                    <Form inline className="navbar-search-props">
                        <FormControl type="text" placeholder="search in panel..." className="mr-sm-2 navbar-search-prop" />
                        <FontAwesomeIcon icon={faSearch} color="#ccb5b5e6" style={{ cursor: 'pointer' }} ></FontAwesomeIcon>
                    </Form> */}
                    <div className="navbar-right-props">
                        {/* <Dropdown>
                            <Dropdown.Toggle id="dropdown-notification" className="dropdown-options-prop">
                                <FontAwesomeIcon icon={faBell} color="#ccb5b5e6" style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1"><img src={SPflag} className="dropdown-image-prop mr-2" alt="spanish_flag" /><span className="dropdown-item-prop">Spanish</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-2"><img src={TRflag} className="dropdown-image-prop mr-2" alt="turkish_flag" /><span className="dropdown-item-prop">Turkish</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><img src={UKflag} className="dropdown-image-prop mr-2" alt="Us_flag" /><span className="dropdown-item-prop">English</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-messages" className="dropdown-options-prop">
                                <FontAwesomeIcon icon={faEnvelope} color="#ccb5b5e6" style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1"><img src={SPflag} className="dropdown-image-prop mr-2" alt="spanish_flag" /><span className="dropdown-item-prop">Spanish</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-2"><img src={TRflag} className="dropdown-image-prop mr-2" alt="turkish_flag" /><span className="dropdown-item-prop">Turkish</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><img src={UKflag} className="dropdown-image-prop mr-2" alt="Us_flag" /><span className="dropdown-item-prop">English</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown> 
                            <Dropdown.Toggle id="dropdown-flags" className="dropdown-options-prop">
                                <img className="dropdown-image-prop" src={UKflag} alt="uk_flag" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1"><img src={SPflag} className="dropdown-image-prop mr-2" alt="spanish_flag" /><span className="dropdown-item-prop">Spanish</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-2"><img src={TRflag} className="dropdown-image-prop mr-2" alt="turkish_flag" /><span className="dropdown-item-prop">Turkish</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-3"><img src={UKflag} className="dropdown-image-prop mr-2" alt="Us_flag" /><span className="dropdown-item-prop">English</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>*/}
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-profile" className="dropdown-options-prop">
                                {/* <img src={UserAvatar} className="user-avatar-prop mr-2" alt="user_avatar" /><span className="ml-2" style={{ color: 'gray' }}>Admin</span> */}
                                {/* <img src={UserAvatar} className="user-avatar-prop mr-2" alt="user_avatar" /> */}
                                <span className="ml-2" style={{ color: 'black' }}> {userName} </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {/* <Dropdown.Item href="#/action-1"><FontAwesomeIcon color="gray" icon={faUserAstronaut} className="mr-2" /><span className="dropdown-item-prop">Profile</span></Dropdown.Item> */}
                                {/* <Dropdown.Item>
                                    <Link to="/">
                                        <img src={dashboard} className="mr-3 widthlisticon" />
                                        <span className="dropdown-item-prop">Dashboard</span>
                                    </Link>
                                </Dropdown.Item>
                                <hr className="hrlinemenu" /> */}
                                {/* <Dropdown.Item href="#/action-3"><span className="dropdown-item-prop">Settings & Privacy</span></Dropdown.Item>
                                <Dropdown.Item href="#/action-4"><span className="dropdown-item-prop">Help</span></Dropdown.Item> */}


                                <Dropdown.Item onClick={this.Clicklogout}>
                                    {/* <img src={signout} className="mr-3 widthlisticon" /> */}
                                    <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                                    <span className="dropdown-item-prop">Sign out</span></Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Navbar>
            </>
        )
    }

}