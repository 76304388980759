/* eslint-disable no-unused-vars */
import React from 'react';
import NavigationBar from './components/navbar';
import Analytics from './analytics';
import UserList from './userlist';
import WebinarHall from './WebinarHall';
import Lobbylite from './Lobbylite';
import BreakoutRoom from './BreakoutRoom';
import Datausage from './Datausage';
import DisplayGallery from './DisplayGallery';
import ArchiveGallery from './ArchiveGallery';
import EntertainmentZone from './EntertainmentZone';
import VideoClick from './VideoClick';
import ImageClick from './ImageClick';
// import lablePiechart from './lablePiechart';
// import AdminPiechart from './AdminPiechart';
import ChartBar from './ChartBar';
import Lobby from './Lobby';
import ChatRoom from './ChatRoom';
// import ProjectList from './projectlist';
import Footer from './components/footer';
import { Route } from 'react-router-dom';
import './assets/styles/main.css';
import AdminRegistration from './AdminRegistration';
import MedexFaculty from './medex-faculty';
import CountryExperience from './country-experience';
import Country1 from './country-1';
import Country2 from './country-2';
import Country3 from './country-3';
import Country4 from './country-4';
import Country5 from './country-5';
import Country6 from './country-6';
import Country7 from './country-7';
import Country8 from './country-8';
import DisplayGallery1 from './display-gallery';
import ArchiveGallery1 from './archive-gallery';
import Archive1 from './archive-1';
import Archive2 from './archive-2';
import Archive3 from './archive-3';
import Archive4 from './archive-4';
import Archive5 from './archive-5';
import Archive6 from './archive-6';
import Archive7 from './archive-7';
import Archive8 from './archive-8';
import Archive9 from './archive-9';
import Archive10 from './archive-10';
import Archive11 from './archive-11';
import BreakTimeZone from './break-time-zone';
import LiveUsers from './liveUsers';
import ArchiveExport from './archiveDataExport';
import DisplayGalleryExport from './DisplayGallaryExport';
import WebinarHallExport from './WebinarHallExport';
import CombineExcel from './CombineExcel'
import CombineExcel1 from './CombineExcel1';
import VideoAnalytics from './videoAnalytics';

class Dashboard extends React.Component {
    render() {
        return (
            <>
                <div>
                    <NavigationBar />
                </div>
                <div className="content">
                    {localStorage.getItem('user-role') === 'admin' &&
                        // <Route exact path="/" component={UserList} />
                        <Route path="/videoAnalytics" component={VideoAnalytics} />
                    }
                    {localStorage.getItem('user-role') === 'admin' &&
                        <Route exact path="/" component={VideoAnalytics} />
                    }
                    {/* {localStorage.getItem('user-role') !== 'admin' && */}
                    {/* <Route path="/Lobby" component={Lobby} /> */}
                    {/* } */}
                    {localStorage.getItem('user-role') !== 'admin' &&
                        <Route exact path="/" component={DisplayGallery} />
                    }
                    {/* <Route path="/dashboard/default" component={Analytics} /> */}
                    {/* <Route path="/userlist" component={UserList} />
                    <Route path="/WebinarHall" component={WebinarHall} />
                    <Route path="/WebinarHallExport" component={WebinarHallExport} />
                    <Route path="/Lobbylite" component={Lobbylite} />
                    <Route path="/BreakoutRoom" component={BreakoutRoom} />
                    <Route path="/adminreg" component={Lobby} /> */}
                    {/* <Route path="/adminreg" component={AdminRegistration} /> */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                       <Route path="/datausage" component={Datausage} />
                    } */}
                    {/* {localStorage.getItem('user-role') !== 'admin' && */}
                    {/* <Route path="/walkin" component={WalkIn} /> */}
                    {/* } */}
                    {/* <Route path="/DisplayGallery" component={DisplayGallery} />
                    <Route path="/ArchiveGallery" component={ArchiveGallery} />
                    <Route path="/EntertainmentZone" component={EntertainmentZone} />
                    <Route path="/VideoClick" component={VideoClick} />
                    <Route path="/imageclick" component={ImageClick} />

                    <Route path="/medexFaculty" component={MedexFaculty} />
                    <Route path="/countryExperience" component={CountryExperience} />
                    <Route path="/country1" component={Country1} />
                    <Route path="/country2" component={Country2} />
                    <Route path="/country3" component={Country3} />
                    <Route path="/country4" component={Country4} />
                    <Route path="/country5" component={Country5} />
                    <Route path="/country6" component={Country6} />
                    <Route path="/country7" component={Country7} />
                    <Route path="/country8" component={Country8} />
                    <Route path="/display_Gallery" component={DisplayGallery1} />
                    <Route path="/archive_Gallery" component={ArchiveGallery1} />
                    <Route path="/archiveExport" component={ArchiveExport} />
                    <Route path="/archive1" component={Archive1} />
                    <Route path="/archive2" component={Archive2} />
                    <Route path="/archive3" component={Archive3} />
                    <Route path="/archive4" component={Archive4} />
                    <Route path="/archive5" component={Archive5} />
                    <Route path="/archive6" component={Archive6} />
                    <Route path="/archive7" component={Archive7} />
                    <Route path="/archive8" component={Archive8} />
                    <Route path="/archive9" component={Archive9} />
                    <Route path="/archive10" component={Archive10} />
                    <Route path="/archive11" component={Archive11} />
                    <Route path="/displayGallaryExport" component={DisplayGalleryExport} />
                    <Route path="/combineExcel" component={CombineExcel} />
                    <Route path="/combineExcel1" component={CombineExcel1} />


                    <Route path="/break-time-zone" component={BreakTimeZone} />
                    <Route path="/live" component={LiveUsers} /> */}
                    {/* <Route path="/videoAnalytics" component={VideoAnalytics} /> */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                       <Route path="/piechart" component={lablePiechart} />
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                       <Route path="/piechart" component={AdminPiechart} />
                    } */}
                    {/* {localStorage.getItem('user-role') !== 'admin' &&
                        <Route path="/barchart" component={ChartBar} />
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Route path="/ChatRoom" component={ChatRoom} />
                    } */}
                </div>
                <div className="footer-props">
                    <Footer />
                </div>
            </>
        );
    }
}
export default Dashboard;