/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from 'react';
import logo from '../assets/images/Salemaxlogo.png';
import logopinclick from '../assets/images/logo.png';

import { Accordion, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faChartPie, faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import '../assets/styles/sidebar.css';
import menu from '../assets/images/menu.png';
import analytics from '../assets/images/analysis.png';
import list from '../assets/images/list.png';
import userlist from '../assets/images/user list.png';
import dashboard from '../assets/images/dash board.png';
import projectlog from '../assets/images/project log.png';
import projectvisited from '../assets/images/project view.png';
import scenevisited from '../assets/images/scene view.png';
import chartimg from '../assets/images/chart.png';
import spendtime from '../assets/images/spent time.png';
import clickimg from '../assets/images/click image.png';
import datausage from '../assets/images/data.png';
import Webinarimg from '../assets/images/Webinar Attendee.png';
import Selfieimg from '../assets/images/Selfie Zone.png';
import chatroomimg from '../assets/images/chat room.png';
import webinarimg from '../assets/images/admin-webinar.png';
import archiveImg from '../assets/images/admin-archive.png'
import countryImg from '../assets/images/admin-country.png'
import displayImg from '../assets/images/admin-display.png'
import facultyImg from '../assets/images/admin-faculty.png'
import entertainmentImg from '../assets/images/admin-entertainment.png'
export default class SideBar extends React.Component {
    OpenMenu = () => {
        var openmenu = document.getElementById('OpenMenu');
        var menu = document.getElementById('Menu');
        var overlayMenu = document.getElementById('OverlayMenu');
        openmenu.style.display = "flex";
        openmenu.classList.add("openmenuanimation");
        openmenu.classList.remove("closmenuanimation");
        overlayMenu.style.display = "flex";
    }

    CloseMenu = () => {
        var openmenu = document.getElementById('OpenMenu');
        var menu = document.getElementById('Menu');
        var overlayMenu = document.getElementById('OverlayMenu');
        openmenu.style.display = "flex";
        openmenu.classList.remove("openmenuanimation");
        openmenu.classList.add("closmenuanimation");
        overlayMenu.style.display = "none";
    }
    render() {
        return (
            <>
                <div className="sidebar-header">
                    {/* <div className="positionlogo"> */}
                    <br />
                    <br />
                    <div className="flexlogo">
                        <img src={logopinclick} className="widthlogo" alt="unite_logo" />

                    </div>

                    {/* </div> */}
                    {/* <h3 className="header-text">PINCLICK</h3> */}
                </div>
                {/* <div className="compdisplay">
                    <hr className="hrline " />
                </div> */}
                <div className="positionmenu">
                    <img src={menu} alt="menu" id="Menu" onClick={this.OpenMenu} className="widthmenu" />

                    {/* <h2 style={{ color: 'white' }} id="Menu" onClick={this.OpenMenu}>menu</h2> */}
                </div>
                <div className="overlaymenu" id="OverlayMenu" onClick={this.CloseMenu} >

                </div>

                <div className="menubg openmenuanimation" id="OpenMenu" >
                    <ul className="flexmenu ">
                        <li>
                            <span className="close" onClick={this.CloseMenu} id="btn-cancel" >&times;</span>
                        </li>
                        {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/default" className="child-menu-item-props"><img src={analytics} className="mr-3 widthlisticon" />Analytics</Button>

                        </li> */}
                        {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/adminreg" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Admin Registration</Button>
                        </li> */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/userlist" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Registration</Button>
                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') !== 'admin' && */}
                        {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/Lobby" className="child-menu-item-props"><img src={datausage} className="mr-3 widthlisticon" />Lobby</Button>
                        </li> */}
                        {/* } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/WebinarHall" className="child-menu-item-props"><img src={webinarimg} className="mr-3 widthlisticon" />Webinar Hall</Button>

                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/WebinarHall" className="child-menu-item-props"><img src={Webinarimg} className="mr-3 widthlisticon" />Webinar Hall</Button>

                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/projectvisited" className="child-menu-item-props"><img src={projectvisited} className="mr-3 widthlisticon" />Project Visited</Button>

                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/countryExperience" className="child-menu-item-props"><img src={Selfieimg} className="mr-3 widthlisticon" />Country Experience Room</Button>

                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/medexFaculty" className="child-menu-item-props"><img src={facultyImg} className="mr-3 widthlisticon" />MEDEX Faculty</Button>
                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/ChatRoom" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Break Time Zone</Button>
                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/feedback" className="child-menu-item-props"><img src={chatroomimg} className="mr-3 widthlisticon" />Chat Room</Button>
                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/datausage" className="child-menu-item-props"><img src={datausage} className="mr-3 widthlisticon" />Datausage</Button>

                            </li>
                        } */}
                        {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/DisplayGallery" className="child-menu-item-props"><img src={displayImg} className="mr-3 widthlisticon" />Display Gallery</Button>
                        </li>
                        <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/ArchiveGallery" className="child-menu-item-props"><img src={archiveImg} className="mr-3 widthlisticon" />Archive Gallery</Button>
                        </li>
                        <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/EntertainmentZone" className="child-menu-item-props"><img src={entertainmentImg} className="mr-3 widthlisticon" />Break Time Zone</Button>
                        </li>
                        <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/live" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Live Users</Button>
                        </li> */}
                        <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/videoAnalytics" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Video Analytics</Button>
                        </li>
                        {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/VideoClick" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Video Analytics</Button>
                        </li> */}
                        {/* <li className="flexmenulist">
                            <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/imageclick" className="child-menu-item-props"><img src={clickimg} className="mr-3 widthlisticon" />Call To Action</Button>
                        </li> */}
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/piechart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                            </li>
                        } */}
                        {/* {localStorage.getItem('user-role') !== 'admin' &&
                            <li className="flexmenulist">
                                <Button onClick={this.CloseMenu} as={NavLink} activeClassName="selected-sidebar-item" to="/barchart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                            </li>
                        } */}
                    </ul>
                </div>
                <div className="sidebar-menu">
                    <br />
                    <br />
                    {/* <h5 className="sidebar-menu-title">Main</h5> */}
                    {/* <Accordion>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0" className="parent-menu">
                            <img src={dashboard} className="mr-3 widthlisticon" /> <span className="parent-menu-text">Dashboard</span>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">

                            <div className="child-menu">
                                
                                {localStorage.getItem('user-role') === 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/userlist" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Registration</Button>
                                }
                                <Button as={NavLink} activeClassName="selected-sidebar-item" to="/walkin" className="child-menu-item-props"><img src={datausage} className="mr-3 widthlisticon" />Walk In</Button>
                                
                                {localStorage.getItem('user-role') === 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/userlogs" className="child-menu-item-props"><img src={projectlog} className="mr-3 widthlisticon" />User Log</Button>
                                }
                                {localStorage.getItem('user-role') === 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/projectvisited" className="child-menu-item-props"><img src={projectvisited} className="mr-3 widthlisticon" />Project Visited</Button>
                                }
                                {localStorage.getItem('user-role') === 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/scenevisited" className="child-menu-item-props"><img src={scenevisited} className="mr-3 widthlisticon" />Scene Visited</Button>
                                }
                                {localStorage.getItem('user-role') === 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/feedback" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Feedback</Button>
                                }
                                <Button as={NavLink} activeClassName="selected-sidebar-item" to="/spendtime" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />User Spent Time</Button>
                                <Button as={NavLink} activeClassName="selected-sidebar-item" to="/imageclick" className="child-menu-item-props"><img src={clickimg} className="mr-3 widthlisticon" />Call To Action</Button>
                                {localStorage.getItem('user-role') === 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/piechart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                                }
                                {localStorage.getItem('user-role') !== 'admin' &&
                                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/barchart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                                }

                            </div>

                        </Accordion.Collapse>
                    </Accordion> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/adminreg" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Admin Registration</Button> */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/userlist" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Registration</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') !== 'admin' && */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/Lobby" className="child-menu-item-props"><img src={datausage} className="mr-3 widthlisticon" />Lobby</Button> */}
                    {/* } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/WebinarHall" className="child-menu-item-props"><img src={webinarimg} className="mr-3 widthlisticon" />Webinar Hall</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/projectvisited" className="child-menu-item-props"><img src={projectvisited} className="mr-3 widthlisticon" />Project Visited</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        // <Button as={NavLink} activeClassName="selected-sidebar-item" to="/Lobbylite" className="child-menu-item-props"><img src={Webinarimg} className="mr-3 widthlisticon" />Lobby lite</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/Lobbylite" className="child-menu-item-props"><img src={Selfieimg} className="mr-3 widthlisticon" />Lobbylite</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        // <Button as={NavLink} activeClassName="selected-sidebar-item" to="/BreakoutRoom" className="child-menu-item-props"><img src={scenevisited} className="mr-3 widthlisticon" />Breakout Room</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        // <Button as={NavLink} activeClassName="selected-sidebar-item" to="/ChatRoom" className="child-menu-item-props"><img src={userlist} className="mr-3 widthlisticon" />Chat Room</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/feedback" className="child-menu-item-props"><img src={chatroomimg} className="mr-3 widthlisticon" />Chat Room</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') !== 'admin' && */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/walkin" className="child-menu-item-props"><img src={datausage} className="mr-3 widthlisticon" />Walk In</Button> */}
                    {/* } */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/medexFaculty" className="child-menu-item-props"><img src={facultyImg} className="mr-3 widthlisticon" />Medex Faculty</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/countryExperience" className="child-menu-item-props"><img src={countryImg} className="mr-3 widthlisticon" />Country Experience</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country1" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 1</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country2" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 2</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country3" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 3</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country4" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 4</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country5" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 5</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country6" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 6</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country7" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 7</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/country8" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Country 8</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/display_Gallery" className="child-menu-item-props"><img src={displayImg} className="mr-3 widthlisticon" />Display Gallery</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive_Gallery" className="child-menu-item-props"><img src={archiveImg} className="mr-3 widthlisticon" />Archive Gallery</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive1" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 1</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive2" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 2</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive3" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 3</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive4" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 4</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive5" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 5</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive6" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 6</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive7" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 7</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive8" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 8</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive9" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 9</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive10" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 10</Button>
                    <Button as={NavLink} activeClassName="selected-sidebar-item" to="/archive11" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive 11</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/break-time-zone" className="child-menu-item-props"><img src={entertainmentImg} className="mr-3 widthlisticon" />Break time zone</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/live" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Live Users</Button> */}
                    {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/videoAnalytics" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Video analytics</Button>
                    }
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/ArchiveGallery" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Archive Gallery</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/EntertainmentZone" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Entertainment Zone</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/VideoClick" className="child-menu-item-props"><img src={spendtime} className="mr-3 widthlisticon" />Video Analytics</Button> */}
                    {/* <Button as={NavLink} activeClassName="selected-sidebar-item" to="/imageclick" className="child-menu-item-props"><img src={clickimg} className="mr-3 widthlisticon" />Call To Action</Button> */}
                    {/* {localStorage.getItem('user-role') === 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/piechart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                    } */}
                    {/* {localStorage.getItem('user-role') !== 'admin' &&
                        <Button as={NavLink} activeClassName="selected-sidebar-item" to="/barchart" className="child-menu-item-props"><img src={chartimg} className="mr-3 widthlisticon" />Chart</Button>
                    } */}
                </div>
                <div className="menu">
                    <h1 className="">

                    </h1>

                </div>
            </>
        );
    }
}