import firebase from './firebase';

export function get_users_list(callBackFunction) {
    let currentComponent = this;
    let userPid = localStorage.getItem('project-id');
    let userrole = localStorage.getItem('user-role');
    var firestore = firebase.firestore();
    let items = firestore.collection("ExpoimageClick");
    if (userrole !== 'admin') {
        items = items.where("projectid", "==", userPid)
    }
    items = items.where("expo", "==", "medex2022")
    let data = [];
    let returnData = [];

    let DatabaseQuery = new Promise((resolve, reject) => {

        items
            .get()
            .then(function (itemSnapshot) {
                itemSnapshot.forEach(function (docItem) {
                    if ((docItem.data().mobilenumber !== '9375705497')
                        && (docItem.data().mobilenumber !== '9979981349')
                        && (docItem.data().mobilenumber !== '+917984604061')
                        && (docItem.data().mobilenumber !== '9016641369')
                        && (docItem.data().mobilenumber !== '9924302720')
                        && (docItem.data().mobilenumber !== '7383810472')
                        && (docItem.data().mobilenumber !== '1234567891')
                        && (docItem.data().mobilenumber !== '1234568791')
                        && (docItem.data().mobilenumber !== '7048853622')
                        && (docItem.data().mobilenumber !== '9662552666')
                        && (docItem.data().mobilenumber !== '7621901935')
                        && (docItem.data().mobilenumber !== '7984604061')
                        && (docItem.data().mobilenumber !== '9664777053')
                        && (docItem.data().mobilenumber !== '9016037231')
                    ) {
                        var docData = docItem.data();
                        docData._id = docItem.id;
                        let mobNumber;
                        if (docData.mobilenumber === "") {
                            mobNumber = "-"
                        } else {
                            mobNumber = docData.mobilenumber
                        }


                        let mydate;
                        if (docData.date === "") {
                            mydate = '-'
                        } else {
                            mydate = new Date(docData.date).toLocaleString('en-IN')
                        }

                        var value = docData.link;
                        let myLink;
                        if (value === undefined) {

                            if (docData.link === 'melzounite') {
                                myLink = 'Video call'
                            } else if (/^\d{10}$/.test(docData.link)) {
                                myLink = 'Audio call'
                            }

                        } else {

                            if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226150737001') {
                                myLink = 'Day 1 Prof. Andrew Coats'
                            } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226155585001') {
                                myLink = 'Day 1 Prof. Alice Cheng'
                            } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226161072001') {
                                myLink = 'Day 1 Prof. Merlin Thomas'
                            } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227754191001') {
                                myLink = 'Day 1 Q&A'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226167578001') {
                                myLink = 'Day 2 Prof. Javed Butler'
                            } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226171187001') {
                                myLink = 'Day 2 Prof. Chantel Mathiew'
                            } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226172298001') {
                                myLink = 'Day 2 Prof. Per-Henrik Groop'
                            } else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6226951513001') {
                                myLink = 'Day 2 Q&A'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227744360001') {
                                myLink = 'Day 3 Treating HFrEF: Should clinical practice be the same around the world?(dicussion)'
                            }

                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227931922001') {
                                myLink = 'Day 4 Prof. Darren Maguire'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227941696001') {
                                myLink = 'Day 4 Prof. Melanie Davis'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227951470001') {
                                myLink = 'Day 4 Prof. Christoph Wanner'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227950910001') {
                                myLink = 'Day 4 Q&A'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227961487001') {
                                myLink = 'Day 5 Prof. Faiez Zannad'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227963439001') {
                                myLink = 'Day 5 Prof. Alice Cheng'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227963287001') {
                                myLink = 'Day 5 Prof. Per-Henrik Groop'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6227958619001') {
                                myLink = 'Day 5 Q&A'
                            }

                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229890850001') {
                                myLink = 'Day 6 Treating T2DM effectively: Should we change our approach or remain as we are?'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229893670001') {
                                myLink = 'Day 7 Prof. Melanie Davis'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891932001') {
                                myLink = 'Day 7 Prof. Christoph Wanner'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891830001') {
                                myLink = 'Day 7 Q&A'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229894529001') {
                                myLink = 'Day 8 Prof. Stefan Anker'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891934001') {
                                myLink = 'Day 8 Prof. Peter Lin'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229894530001') {
                                myLink = 'Day 8 Prof. Merlin Thomas'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6229891935001') {
                                myLink = 'Day 8 Q&A'
                            }



                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6231187226001') {
                                myLink = 'Day 9 Treating CKD: Is there more to be done and when?'
                            }




                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224010942001') {
                                myLink = "Improving Patient's Daily Activity"
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224018160001') {
                                myLink = 'Significance of HFpEF Treatment'
                            }

                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224021146001') {
                                myLink = 'Which is most important unmet need of HF patients'
                            }
                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224051877001') {
                                myLink = 'Importance of Functional Capacity Studies'
                            }

                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224059420001') {
                                myLink = 'What is more important to patients: Ooi or longevity'
                            }

                            else if (value === 'https://drive.google.com/file/d/11Ehhu8yKALuBYjHfK7bzIXGsZxZirkEH/view?usp=sharing') {
                                myLink = 'Heart Failure Program PDF'
                            }
                            else if (value === 'https://drive.google.com/file/d/1MiryvBi9VJClsKHmN3VZVwHskJTeRjcW/view?usp=sharing') {
                                myLink = 'Emperial - Stydu Design PDF'
                            }


                            else if (value === 'https://drive.google.com/file/d/1Vk2vAw41B_gl3i3JoyDZ9Oh2wJOaGSO_/view?usp=sharing') {
                                myLink = 'EMPEROR - Study Design PDF'
                            }

                            else if (value === 'https://drive.google.com/file/d/122UIzYvcdWdlEb-x6VzE0549vGi7xc_E/view?usp=sharing') {
                                myLink = 'EMPULSE - Study Design PDF'
                            }
                            else if (value === 'https://drive.google.com/file/d/1EfPiGLm-RLPREUXFxSH2PsEpSXPkNVTI/view?usp=sharing') {
                                myLink = 'EMPACT-MI static Infographic PDF'
                            }


                            else if (value === 'https://drive.google.com/file/d/1Ewobqs6r_63z_-mTql8GrF_hPfUt3bpB/view?usp=sharing') {
                                myLink = 'EMPAROR-Reduced KCCO sub-analysis Infographic PDF'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224755410001') {
                                myLink = 'Why is EMPA-KIDNEY important to do after the EMPA-REG OUTCOME trial?'
                            }

                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224758394001') {
                                myLink = 'Can you describe how EMPA-KIDNEY is different from the other dedicated kidney trials with SGLT2I?'
                            }

                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224760422001') {
                                myLink = 'How could the results impact the way we treat patients with CKD?'
                            }


                            else if (value === 'https://players.brightcove.net/1628196552001/default_default/index.html?videoId=6224762696001') {
                                myLink = 'Who is conducting the EMPA-KIDNEY study?'
                            }
                            else {
                                myLink = 'Microsite'
                            }
                        }

                        if (docData.mobilenumber !== 'undefined' && docData.username !== 'undefined' && docData.mobilenumber !== 'null' && docData.username !== 'null') {
                            let CompleteData = {
                                id: docItem.id,
                                mobilenumber: mobNumber,
                                username: docData.username,
                                projectid: docData.projectid,
                                projectname: docData.projectname,
                                email: docData.email,
                                link: myLink,
                                date: mydate,
                                filterTime: docData.date,
                                myfilterstartdate: new Date(docData.date).toLocaleDateString()
                            }
                            data.push(CompleteData)
                        }
                    }
                })
                var sortArray = data.sort(function (a, b) { return b.filterTime - a.filterTime });

                let mydata = {
                    columns: [
                        {
                            label: 'Mobile Number',
                            field: 'mobilenumber',
                            sort: 'asc',
                            width: 150
                        },
                        {
                            label: 'User Name',
                            field: 'username',
                            sort: 'asc',
                            width: 150
                        },
                        {
                            label: 'Projectname',
                            field: 'projectname',
                            sort: 'asc',
                            width: 150
                        },
                        {
                            label: 'Link',
                            field: 'link',
                            sort: 'asc',
                            width: 150
                        },
                        {
                            label: 'Date',
                            field: 'date',
                            sort: 'asc',
                            width: 150
                        },

                    ],
                    rows: sortArray,
                };
                resolve(mydata);
            }).catch(error => {
                reject(error);
            });
    });

    DatabaseQuery.then(result => {
        callBackFunction(result);
    }).catch(error => {
        console.error('Database query error for ');
    });
}