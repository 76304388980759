/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableToExcel from "@linways/table-to-excel";
import { MDBDataTable } from 'mdbreact';

import "react-datepicker/dist/react-datepicker.css";
import firebase from './firebase';
import { Form, FormControl, Button } from 'react-bootstrap';
import { getCurrentPageAnalytics } from './firebase-function';

let fromDate;
let toDate;
let toHHMMSS;
let typeValue;

export default class WebinarHall extends React.Component {
    static data = [];
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            dataUser: [],
            startDateFrom: '',
            startDateTO: '',
            totalvisitor: '',
        }
        this.filterName = this.filterName.bind(this);
    }
    componentDidMount() {

        // let userrole = localStorage.getItem('user-role');
        // if (userrole === 'user') {
        //     this.get_total_visitor();
        // }

        getCurrentPageAnalytics('MEDEX2022LiveUsers', this.manageMyTable);
        // this.getCountOfCountryVisitors();

    }

    getCountOfCountryVisitors = () => {
        const firestore = firebase.firestore();
    // let items = firestore.collection('MEDEX2022LiveUsers');
    let DatabaseQuery = new Promise((resolve, reject) => {
        firestore.collectionGroup('MEDEX2022LiveUsers').where('expo', '==', 'medex2022').get().then(function (itemSnapshot) {
            let allEntry = [];
            itemSnapshot.forEach(function (docItem) {
                if ((docItem.data().mobilenumber !== '9375705497')
                    && (docItem.data().mobilenumber !== '8347763858')
                    && (docItem.data().mobilenumber !== '8766455861')
                    && (docItem.data().mobilenumber !== '8401835825')
                    && (docItem.data().mobilenumber !== '9016641369')
                    && (docItem.data().mobilenumber !== '08866670336')
                    && (docItem.data().mobilenumber !== '7621901935')
                    && (docItem.data().mobilenumber !== '8160968264')
                    && (docItem.data().email !== 'hevan@shilpmis.com')
                    && (docItem.data().email !== 'mohit@shilpmis.com')
                    // && (docItem.data().mobilenumber !== '9537262686')

                ) {
                    let docData = docItem.data();
                    docData._id = docItem.id;

                    allEntry.push(docData);
                    // if (isSceneVisitPage) {
                    //     if (docData.fullname !== 'undefined'
                    //         && docData.useremail !== 'undefined'
                    //         && docData.mobilenumber !== 'undefined'
                    //         && docData.fullname !== null
                    //         && docData.useremail !== null
                    //         && docData.mobilenumber !== null) {

                    //         allEntry.push(docData);
                    //     }
                    // } else {
                    //     if (docData.fullname !== 'undefined'
                    //         && docData.useremail !== 'undefined'
                    //         && docData.mobilenumber !== 'undefined'
                    //         && docData.fullname !== null
                    //         && docData.useremail !== null
                    //         && docData.mobilenumber !== null) {

                    //         allEntry.push(docData);
                    //     }
                    // }
                }
            });
            resolve(allEntry);
        }).catch(error => {
            reject(error);
        });
    });

    DatabaseQuery.then(result => {
        console.log('All Data:::', result)
        callBackFunction(result);
    }).catch(error => {
        console.error('Database query error is', error);
    });
    }


    manageMyTable = (databaseEntries) => {
        console.log("DATA :: ", databaseEntries)
        let formattedData = [];
        for (let i = 0; i < databaseEntries.length; i++) {
            let endDate;
            if (databaseEntries[i].endTime === "") {
                let addSec = databaseEntries[i].startTime + 30000;
                let addedTime = new Date(addSec).toLocaleString('en-IN');
                endDate = addedTime
            } else {
                endDate = new Date(databaseEntries[i].endTime).toLocaleString('en-IN');
            }

            let startDate;
            if (databaseEntries[i].startTime === "") {
                startDate = "-"
            } else {
                startDate = new Date(databaseEntries[i].startTime).toLocaleString('en-IN');
            }
            let mobNumber;
            if (databaseEntries[i].mobileNumber === "") {
                mobNumber = "-"
            } else {
                mobNumber = databaseEntries[i].mobileNumber
            }

            let diff = Math.abs(new Date(databaseEntries[i].endTime) - new Date(databaseEntries[i].startTime));
            let different = (Math.abs(new Date(databaseEntries[i].endTime) - new Date(databaseEntries[i].startTime))/1000/60);
            let ms = diff % 1000;
            diff = (diff - ms) / 1000
            let ss = diff % 60;
            diff = (diff - ss) / 60
            let mm = diff % 60;
            let minutes = different;
            diff = (diff - mm) / 60
            let hh = diff % 24;
            let days = (diff - hh) / 24

            if (hh < 10) {
                hh = '0' + hh;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            if (ss < 10) {
                ss = '0' + ss;
            }

            if (days === 0) {
                diff = hh + ":" + mm + ":" + ss;
            }
            else {
                diff = days + "/" + hh + ":" + mm + ":" + ss;
            }

            if (databaseEntries[i].endTime === "") {
                diff = "00:00:30";
            }

            let userType;
            if (diff <= "00:00:30") {
                userType = 'Regular'
            } else {
                userType = 'Active'
            }

            if (databaseEntries[i].roomName === 'webinarHall') {
                typeValue = 'Webinar-hall'
            }
            // if (databaseEntries[i].type === 4) {
            //     typeValue = 'Webinar-Hall'
            // } else if (databaseEntries[i].type === 3) {
            //     typeValue = 'Lobby'
            // } else if (databaseEntries[i].type === 17 || databaseEntries[i].type === 18) {
            //     typeValue = 'Lobby-lite'
            // } else if (databaseEntries[i].type === 15) {
            //     typeValue = 'Breakout-Room'
            // } else if (databaseEntries[i].type === 7) {
            //     typeValue = 'Chat-Room'
            // } else if (databaseEntries[i].type === 13) {
            //     typeValue = 'Archieved-Gallary'
            // } else if (databaseEntries[i].type === 14) {
            //     typeValue = 'Display-Gallary'
            // } else if (databaseEntries[i].type === 5) {
            //     typeValue = 'Entertainment-zone'
            // }


            let CompleteData = {
                id: databaseEntries[i]._id,
                fullname: databaseEntries[i].name,
                useremail: databaseEntries[i].email,
                mobilenumber: mobNumber,
                country: databaseEntries[i].country,
                // country: databaseEntries[i].countryName,
                designation: databaseEntries[i].designation,
                speciality: databaseEntries[i].speciality,
                // type: typeValue,

                // username: databaseEntries[i].username,
                // projectid: databaseEntries[i].projectid,
                // projectname: databaseEntries[i].projectname,
                starttime: startDate,
                endtime: endDate,
                duration: diff,
                usertype: userType,
                filterTime: databaseEntries[i].startTime,
                myfilterstartdate: new Date(databaseEntries[i].startTime).toLocaleDateString()
            }

            if (databaseEntries[i].roomName === 'webinarHall') {
                // console.log('Time', minutes, 'duration', diff);
                formattedData.push(CompleteData)
            }
        }
        this.formatTable(formattedData);
    }

    formatTable = (myArrayData) => {
        var sortArray = myArrayData.sort(function (a, b) { return b.filterTime - a.filterTime });
        let mydata = {
            columns: [
                {
                    label: 'User Name',
                    field: 'fullname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Email',
                    field: 'useremail',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Mobile Number',
                    field: 'mobilenumber',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Country',
                    field: 'country',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Designation',
                    field: 'designation',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Speciality',
                    field: 'speciality',
                    sort: 'asc',
                    width: 150
                },

                {
                    label: 'Start-time',
                    field: 'starttime',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'End-time',
                    field: 'endtime',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Duration',
                    field: 'duration',
                    sort: 'asc',
                    width: 150
                },

            ],
            rows: sortArray,
        };
        this.setState({ data: mydata, data2: mydata });
    }

    // get_total_visitor() {
    //     let currentComponent = this;
    //     const firestoreer = firebase.firestore();
    //     const docReff = firestoreer.collection("totalVisitor");
    //     docReff.get().then(function (querySnapshot) {
    //         querySnapshot.forEach(function (doc) {
    //             if (doc.data().expo === 'pinclick') {
    //                 const mydata = doc.data();
    //                 const incresCounter = mydata.counter;
    //                 currentComponent.setState({
    //                     totalvisitor: incresCounter
    //                 })
    //             }
    //         })
    //     })
    // }

    // manageMyTable = (databaseEntries) => {
    //     let formattedData = [];
    //     for (let i = 0; i < databaseEntries.length; i++) {
    //         let CompleteData = {
    //             mobilenumber: databaseEntries[i].mobilenumber,
    //             username: databaseEntries[i].username,
    //             starttime: databaseEntries[i].starttime,
    //         }
    //         formattedData.push(CompleteData);
    //     }
    //     this.get_users_list(formattedData);
    // }

    // get_users_list = (myArray) => {

    //     var result = [];
    //     myArray.reduce(function (res, value) {
    //         if (!res[value.mobilenumber]) {
    //             res[value.mobilenumber] = { mobilenumber: value.mobilenumber, username: value.username, starttime: 0 };
    //             result.push(res[value.mobilenumber])
    //         }
    //         res[value.mobilenumber].starttime += value.starttime;
    //         return res;
    //     }, {});


    //     let mydata = {
    //         columns: [
    //             {
    //                 label: 'Fullname',
    //                 field: 'username',
    //                 sort: 'asc',
    //                 width: 150
    //             },
    //             {
    //                 label: 'Mobileno',
    //                 field: 'mobilenumber',
    //                 sort: 'asc',
    //                 width: 150
    //             },

    //         ],
    //         rows: result,
    //     };
    //     this.setState({ data: mydata, data2: mydata });
    // }

    filterName(event) {
        const tempData = this.filterByValue(this.state.data.rows, event.target.value);
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }
    filterByValue(array, value) {
        return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    handleChange = date => {
        this.setState({
            startDateFrom: date
        });
    };

    handleChangeTo = date => {
        this.setState({
            startDateTO: date
        });
    };

    handleFilter = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value;
        toDate = document.getElementById('toDate').value;
        // const  pikerDate = document.getElementById('datepicker').value;
        // console.log("pikerDate :: ",pikerDate)
        if (fromDate === "") {
            alert("Please enter From date")
        } else if (toDate === "") {
            alert("Please enter To date")
        } else {
            const tempData = this.filterByValueDate(this.state.data);
            this.setState({
                data2: tempData
            });

        }
    }

    filterByValueDate(array) {

        return array.filter((item) =>

            item.starttime >= fromDate && item.starttime <= toDate
        );

    }

    handleCancle = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value = "";
        toDate = document.getElementById('toDate').value = "";
        this.setState({
            startDateFrom: '',
            startDateTO: ''
        })
        this.componentDidMount()
    }

    firstDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '21/1/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    secondDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '28/1/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    thirdDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '4/2/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    fourthDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '11/2/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    fifthDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '18/2/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    sixthDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '24/2/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    seventhDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '4/3/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    eighthDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '11/3/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    ninthDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '16/3/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    tenthDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '17/3/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    eleventhDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '23/3/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    twelveDate=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '31/3/2022');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    allData=(e)=>{
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    downloadButtonExcel = (e) => {
        e.preventDefault();
        const currentDate = new Date().getDate().toString().padStart(2, "0") + "-" + (new Date().getMonth() + 1).toString().padStart(2, "0") + "-" + new Date().getFullYear();
        TableToExcel.convert(document.getElementById("table-to-xls"), {
            name: `MEDEX 2022 Webinar Hall-${currentDate}.xlsx`,
            sheet: {
                name: "Sheet 1"
            }
        });
    }
    render() {
        return (
            <div>
                <div className="flexheading">
                    <h3 className="fontweight fontfamily">Webinar Hall</h3>
                    <div className="flexheading">
                        {/* {localStorage.getItem('user-role') === 'user' &&
                            <label className="countlabel marginrightlabel"> Total Visitor :  <label className="numbers">{this.state.totalvisitor}</label></label>
                        } */}
                        {/* <label className="countlabel marginrightlabel"> Count :  <label className="numbers">{this.state.data2.length}</label></label> */}
                        <FormControl type="text" placeholder="search" onChange={(e) => this.filterName(e)} className="mr-sm-2 navbar-search-prop" />

                    </div>
                </div>
                <button className="registerbutton" onClick={this.allData} >
                    All 
                </button>
                <div class="schedule-tab">
                <div class="schedule-tab__item " data-tab="tab-1" onClick={this.firstDate} >
                    <h2>Day 01</h2>
                    <p>21/01/22</p>
                </div>
                <div class="schedule-tab__item " data-tab="tab-2" onClick={this.secondDate} >
                    <h2>Day 02</h2>
                    <p>28/01/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-3" onClick={this.thirdDate} >
                    <h2>Day 03</h2>
                    <p>04/02/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-4" onClick={this.fourthDate} >
                    <h2>Day 04</h2>
                    <p>11/02/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-5" onClick={this.fifthDate} >
                    <h2>Day 05</h2>
                    <p>18/02/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-6" onClick={this.sixthDate} >
                    <h2>Day 06</h2>
                    <p>24/02/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-7" onClick={this.seventhDate} >
                    <h2>Day 07</h2>
                    <p>04/03/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-8" onClick={this.eighthDate} >
                    <h2>Day 08</h2>
                    <p>11/03/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-9" onClick={this.ninthDate} >
                    <h2>Day 09</h2>
                    <p>16/03/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-10" onClick={this.tenthDate} >
                    <h2>Day 10</h2>
                    <p>17/03/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-11" onClick={this.eleventhDate} >
                    <h2>Day 11</h2>
                    <p>23/03/22</p>
                </div>
                <div class="schedule-tab__item" data-tab="tab-12" onClick={this.twelveDate} >
                    <h2>Day 12</h2>
                    <p>31/03/22</p>
                </div>
            </div>
                <div className="flexheading">

                    <Form inline className=" marginbottomform">
                        {/* <DatePicker
                            id="fromDate"
                            selected={this.state.startDateFrom}
                            onChange={this.handleChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            className="marginrightdate navbar-search-prop"
                        />
                        <DatePicker
                            id="toDate"
                            selected={this.state.startDateTO}
                            onChange={this.handleChangeTo}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            className="marginrightdate navbar-search-prop"

                        />
                    
                        <div className="flexrow">
                            <button onClick={this.handleFilter} className="marginrightdate dashboardbtn">
                                Filter
                            </button>
                            <button onClick={this.handleCancle} className="marginrightdate dashboardbtn">
                                CANCEL
                            </button>
                        </div> */}
                        <div>
                            {/* <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button  downloadbtn"
                                table="table-to-xls"
                                filename="MEDEX 2022 Webinar Hall Attendee"
                                sheet="MEDEX 2022 Webinar Hall"
                                buttonText="Download as XLS" /> */}

                             <button className="download-table-xls-button downloadbtn"
                                onClick={this.downloadButtonExcel}
                            >Download As Excel</button>
                        </div>
                    </Form>
                </div>
                <div className="overflowtableanalysis">

                    <MDBDataTable
                        searching={false}
                        paging={true}
                        data={this.state.data2}
                    />

                </div>
                <div className="overflowtableanalysis" style={{ display: 'none' }}>
                    <table id="table-to-xls" className="table table-hover margintoptable">
                        <thead>
                            <tr>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>No.</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>User Name</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Country</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Mobile Number</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Email</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Designation</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Speciality</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Start-time</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>End-time</th>
                                <th scope="col" data-f-name="Calibri" data-f-bold={true}>Duration</th>

                            </tr>
                        </thead>
                        {this.state.data2.rows &&
                            <tbody>
                                {Object.values(this.state.data2.rows).map((users, index) => {
                                    return (
                                        <tr key={index}>
                                            <td data-f-name="Calibri">{index + 1}</td>
                                            <td data-f-name="Calibri">{users.fullname}</td>
                                            <td data-f-name="Calibri">{users.country}</td>
                                            <td data-f-name="Calibri">{users.mobilenumber}</td>
                                            <td data-f-name="Calibri">{users.useremail}</td>
                                            <td data-f-name="Calibri">{users.designation}</td>
                                            <td data-f-name="Calibri">{users.speciality}</td>
                                            <td data-f-name="Calibri">{users.starttime}</td>
                                            <td data-f-name="Calibri">{users.endtime}</td>
                                            <td data-f-name="Calibri">{users.duration}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }
}