/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React, { createContext } from 'react';

export const AuthContext = createContext();

export class AuthProvider extends React.Component {

//   constructor() {
//     super()
//     this.login = this.login.bind(this)
//     this.logout = this.logout.bind(this)
//   }


  login=newUserLoggedIn=> {
    this.setState({ isUserLoggedIn: newUserLoggedIn })
  };

  logout=newUserLoggedIn=> {
    this.setState({ isUserLoggedIn: newUserLoggedIn })
  };

  state = { isUserLoggedIn: false,
    login: this.login,
    logout: this.logout 
  };
    
  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {this.props.children}
      </AuthContext.Provider>
    )
  }
}

export const AuthConsumer = AuthContext.Consumer;
