/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import firebase from './firebase';
import { Form, FormControl, Button } from 'react-bootstrap';

let fromDate;
let toDate;

export default class Datausage extends React.Component {
    static data = [];
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            startDateFrom: '',
            startDateTO: '',
            totalDatausage: ''
        }
        this.filterName = this.filterName.bind(this);
    }
    componentDidMount() {

        this.get_users_list();
    }
    //most visited user

    get_users_list() {
        // e.preventDefault();
        let currentComponent = this;
        let userPid = localStorage.getItem('project-id');
        let userrole = localStorage.getItem('user-role');
        var firestore = firebase.firestore();
        if (userrole === 'admin') {

            let items = firestore.collection("ExposceneVisitLogs");
            // items = items.where("starttime", ">=", 1596774600000)
            items = items.where("expo", "==", "pinclick")
            // const items = firestore.collection("ExposceneVisitLogs");
            let data = [];
            let myTotalUsage = 0;
            let UsageData = new Promise((resolve, reject) => {
                items.get().then(function (itemSnapshot) {
                    itemSnapshot.forEach(function (docItem) {
                        if ((docItem.data().mobilenumber !== '9375705497')
                            && (docItem.data().mobilenumber !== '9979981349')
                            && (docItem.data().mobilenumber !== '+917984604061')
                            && (docItem.data().mobilenumber !== '9016641369')
                            && (docItem.data().mobilenumber !== '9924302720')
                            && (docItem.data().mobilenumber !== '7383810472')
                            && (docItem.data().mobilenumber !== '1234567891')
                            && (docItem.data().mobilenumber !== '1234568791')
                            && (docItem.data().mobilenumber !== '7048853622')
                            && (docItem.data().mobilenumber !== '9662552666')
                            && (docItem.data().mobilenumber !== '7621901935')
                            && (docItem.data().mobilenumber !== '7984604061')
                            && (docItem.data().mobilenumber !== '9664777053')
                            && (docItem.data().mobilenumber !== '9016037231')
                        ) {
                            var docData = docItem.data();
                            // console.log("DOC DATA :: ",docData);
                            docData._id = docItem.id;
                            // console.log("DOC DATA ID :: ",docData.currenttime);

                            let dataUsage;
                            if (docData.datausage === undefined) {
                                dataUsage = 0
                            } else {
                                dataUsage = docData.datausage
                            }

                            myTotalUsage += dataUsage
                            if (docData.mobilenumber !== 'undefined' && docData.username !== 'undefined' && docData.mobilenumber !== 'null' && docData.username !== 'null') {
                                let CompleteData = {
                                    id: docItem.id,
                                    projectname: docData.projectname,
                                    datausage: dataUsage
                                }
                                data.push(CompleteData)
                            }
                        }
                    })
                    resolve('Done');
                }).catch(error => {
                    reject(error);
                })
            });

            UsageData.then(dataResult => {
                // console.log("DATA ARRAY :: ", data)
                // console.log("Total Usage :: ", myTotalUsage)

                var result = [];
                data.reduce(function (res, value) {
                    if (!res[value.projectname]) {
                        res[value.projectname] = { projectname: value.projectname, datausage: value.datausage };
                        result.push(res[value.projectname])
                    }
                    res[value.projectname].datausage += value.datausage;
                    return res;
                }, {});

                // console.log("My Sum Result => :: ",result)
                var sortArray = result.sort(function (a, b) { return b.datausage - a.datausage });
                // console.log("Demo desc :: ", sortArray);
                // console.log("My Sum Result => :: ", result)

                currentComponent.setState({
                    data: sortArray,
                    data2: sortArray,
                    totalDatausage: myTotalUsage
                });
            }).catch(error => {
                console.error('Data usage Error', error);
            })
        }
    }

    filterName(event) {
        const tempData = this.filterByValue(this.state.data, event.target.value);
        this.setState({
            data2: tempData
        });
    }
    filterByValue(array, value) {
        return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    handleChange = date => {
        this.setState({
            startDateFrom: date
        });
    };

    handleChangeTo = date => {
        this.setState({
            startDateTO: date
        });
    };

    handleFilter = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value;
        toDate = document.getElementById('toDate').value;
        // const  pikerDate = document.getElementById('datepicker').value;
        // console.log("pikerDate :: ",pikerDate)
        if (fromDate === "") {
            alert("Please enter From date")
        } else if (toDate === "") {
            alert("Please enter To date")
        } else {
            const tempData = this.filterByValueDate(this.state.data);
            this.setState({
                data2: tempData
            });

        }
    }

    filterByValueDate(array) {

        return array.filter((item) =>

            item.starttime >= fromDate && item.starttime <= toDate
        );

    }

    handleCancle = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value = "";
        toDate = document.getElementById('toDate').value = "";
        this.setState({
            startDateFrom: '',
            startDateTO: ''
        })
        this.componentDidMount()
    }

    render() {
        return (
            <div>
                <div className="flexheading">
                    <h3 className="fontweight fontfamily">Datausage</h3>
                    <div className="flexheading">
                        {localStorage.getItem('user-role') === 'admin' &&
                            <label className="countlabel marginrightlabel"> Total Datausage : <label className="numbers">{this.state.totalDatausage}</label></label>
                        }
                        {localStorage.getItem('user-role') === 'admin' &&
                            <label className="countlabel marginrightlabel"> Count :  <label className="numbers">{this.state.data2.length}</label></label>
                        }
                        <FormControl type="text" placeholder="search" onChange={this.filterName} className="mr-sm-2 navbar-search-prop" />
                    </div>
                </div>
                <div className="flexheading margintopflex">

                    <Form inline className=" marginbottomform">
                        {/* <DatePicker
                            id="fromDate"
                            selected={this.state.startDateFrom}
                            onChange={this.handleChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            className="marginrightdate navbar-search-prop"
                        />
                        <DatePicker
                            id="toDate"
                            selected={this.state.startDateTO}
                            onChange={this.handleChangeTo}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            className="marginrightdate navbar-search-prop"

                        />
                       
                        <div className="flexrow">
                            <button onClick={this.handleFilter} className="marginrightdate dashboardbtn">
                                Filter
                            </button>
                            <button onClick={this.handleCancle} className="marginrightdate dashboardbtn">
                                CANCEL
                             </button>
                        </div> */}
                        <div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button  downloadbtn"
                                table="table-to-xls"
                                filename="Scenevisit"
                                sheet="Scenevisit"
                                buttonText="Download as XLS" />
                        </div>
                    </Form>
                </div>
                <div className="overflowtableanalysis">

                    <table id="table-to-xls" className="table table-hover margintoptable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                {/* <th scope="col">Mobile Number</th>
                                <th scope="col">User Name</th> */}
                                {/* <th scope="col">Projectid</th> */}
                                <th scope="col">Projectname</th>
                                {/* <th scope="col">Start-time</th> */}
                                {/* <th scope="col">End-time</th> */}
                                {/* <th scope="col">Duration</th> */}
                                {/* <th scope="col">Sceneid</th> */}
                                {/* <th scope="col">Scenename</th> */}
                                <th scope="col">Datausage</th>

                            </tr>
                        </thead>
                        <tbody>
                            {this.state.data2.map((users, index) => {
                                return (
                                    <tr key={users.id}>
                                        <td>{index + 1}</td>
                                        {/* <td>{users.mobilenumber}</td>
                                        <td>{users.username}</td> */}
                                        {/* <td>{users.projectid}</td> */}
                                        <td>{users.projectname}</td>
                                        {/* <td>{users.starttime}</td> */}
                                        {/* <td>{users.endtime}</td> */}
                                        {/* <td>{users.duration}</td> */}
                                        {/* <td>{users.sceneid}</td> */}
                                        {/* <td>{users.scenename}</td> */}
                                        <td>{users.datausage}</td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}