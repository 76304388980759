/**
 * This file containes firebase calls from different components in admin panel
 * So as to affect changes by changing only in this file for any upgradation
 */

import firebase from './firebase';
const expoName = 'medex2022';

/**
 * This function will output array of database query in callback function
 * for asked collectionName in their respective components
 * 
 * @param {Asked collection name} collectionName 
 * @param {Data array returning function} callBackFunction 
 */
export function getCurrentPageData(collectionName, callBackFunction, isSceneVisitPage = false) {
    let userPid = localStorage.getItem('project-id');
    let userrole = localStorage.getItem('user-role');
    const firestore = firebase.firestore();
    let items = firestore.collection(collectionName);
    items = items.where("expo", "==", expoName)
    if (userrole !== 'admin') {
        items = items.where("projectid", "==", userPid)
    }

    let DatabaseQuery = new Promise((resolve, reject) => {
        items.get().then(function (itemSnapshot) {
            let allEntry = [];
            itemSnapshot.forEach(function (docItem) {
                if ((docItem.data().mobileNumber !== '9375705497')
                    && (docItem.data().mobileNumber !== '9979981349')
                    && (docItem.data().mobileNumber !== '+917984604061')
                    && (docItem.data().mobileNumber !== '9016641369')
                    && (docItem.data().mobileNumber !== '9924302720')
                    && (docItem.data().mobileNumber !== '7383810472')
                    && (docItem.data().mobileNumber !== '1234567891')
                    && (docItem.data().mobileNumber !== '1234568791')
                    && (docItem.data().mobileNumber !== '7048853622')
                    && (docItem.data().mobileNumber !== '9662552666')
                    && (docItem.data().mobileNumber !== '7621901935')
                    && (docItem.data().mobileNumber !== '7984604061')
                    && (docItem.data().mobileNumber !== '9664777053')
                    && (docItem.data().mobileNumber !== '9016037231')
                    && (docItem.data().mobileNumber !== '8347763858')
                    && (docItem.data().mobileNumber !== '9537262686')
                    && (docItem.data().mobileNumber !== '08866670336')
                    && (docItem.data().email !== 'simminarang2508@gmail.com')
                    && (docItem.data().email !== 'testqw@gamil.com')
                    && (docItem.data().email !== 'test@gmail.com')
                    && (docItem.data().email !== 'ishadesai2395@gmail.com')
                    && (docItem.data().email !== 'abc@gmail.com')
                    && (docItem.data().email !== 'a@a.com')
                    && (docItem.data().email !== 'yaw.asare@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'ibrahim.adaro@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'yashdesai0934@gmail.com')
                    && (docItem.data().email !== 'yash.desai23@yahoo.com')
                    && (docItem.data().email !== 'virajnmerai16@gmail.com')
                    && (docItem.data().email !== 'parth@gmail.com')
                    && (docItem.data().name !== 'test')
                    && (docItem.data().name !== 'Ritika')
                    && (docItem.data().name !== 'Gayatri')
                    && (docItem.data().name !== 'xxxx')
                    && (docItem.data().name !== 'Jigar Bhavsar')
                    && (docItem.data().name !== 'Ashish')
                    && (docItem.data().name !== 'ashish')
                    && (docItem.data().name !== 'ashish Kanani')
                    && (docItem.data().name !== 'Alvin')
                    && (docItem.data().name !== 'kathan')
                    && (docItem.data().name !== 'Kathan')
                    && (docItem.data().name !== 'Simran')
                    && (docItem.data().email !== 'tousifpinjari1@gmail.com')
                    && (docItem.data().email !== 'raed.alnagi@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'ann.kungu@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'zeina.saifan@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'denis.abungu@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'farah.al_saify@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'rami.ziadeh@boehringer-ingelheim.com')
                    && (docItem.data().email !== 'testd@gmail.com')
                    && (!docItem.data().email.includes('melzo.com'))
                    && (!docItem.data().email.includes('shilpmis.com'))
                ) {
                    let docData = docItem.data();
                    docData._id = docItem.id;

                    allEntry.push(docData);
                    // if (isSceneVisitPage) {
                    //     if (docData.mobilenumber !== 'undefined'
                    //         && docData.username !== 'undefined'
                    //         && docData.mobilenumber !== null
                    //         && docData.username !== null
                    //         && docData.scenename !== '-') {

                    //         allEntry.push(docData);
                    //     }
                    // } else {
                    //     if (docData.mobilenumber !== 'undefined'
                    //         && docData.username !== 'undefined'
                    //         && docData.mobilenumber !== null
                    //         && docData.username !== null) {

                    //         allEntry.push(docData);
                    //     }
                    // }
                }
            });
            resolve(allEntry);
        }).catch(error => {
            reject(error);
        });
    });

    DatabaseQuery.then(result => {
        callBackFunction(result);
    }).catch(error => {
        console.error('Database query error for ' + collectionName + ' is', error);
    });
}


/**
 * This function will output array of database query in callback function
 * for asked collectionName in their respective components
 * 
 * @param {Asked collection name} collectionName 
 * @param {Data array returning function} callBackFunction 
 */
export function getCurrentPageAnalytics(collectionName, callBackFunction, isSceneVisitPage = false) {
    // let userPid = localStorage.getItem('project-id');
    // let userrole = localStorage.getItem('user-role');
    const firestore = firebase.firestore();
    let items = firestore.collection(collectionName);
    items = items.where("expo", "==", expoName).orderBy("email")
    // citiesRef.orderBy("name").limit(3);
    // if (userrole !== 'admin') {
    //     items = items.where("projectid", "==", userPid)
    // }

    let DatabaseQuery = new Promise((resolve, reject) => {
        items.get().then(function (itemSnapshot) {
            let allEntry = [];
            itemSnapshot.forEach(function (docItem) {
                if ((docItem.data().mobilenumber !== '9375705497')
                    && (docItem.data().mobilenumber !== '8347763858')
                    && (docItem.data().mobilenumber !== '8766455861')
                    && (docItem.data().mobilenumber !== '8401835825')
                    && (docItem.data().mobilenumber !== '9016641369')
                    && (docItem.data().mobilenumber !== '08866670336')
                    && (docItem.data().mobilenumber !== '7621901935')
                    && (docItem.data().mobilenumber !== '8160968264')
                    && (docItem.data().email !== 'hevan@shilpmis.com')
                    && (docItem.data().email !== 'mohit@shilpmis.com')
                    && (docItem.data().email !== 'ashish@melzo.com')
                    && (docItem.data().email !== 'helly@melzo.com')
                    && (docItem.data().email !== 'bhavik@melzo.com')
                    && (docItem.data().email !== 'viraj@melzo.com')
                    && (docItem.data().email !== 'khagesh92@shilpmis.com')
                    && (docItem.data().email !== 'kathandjv@gmail.com')
                    && (docItem.data().email !== 'yash@melzo.com')
                    && (docItem.data().email !== 'yashdesai0934@gmail.com')
                    && (docItem.data().email !== 'yash.desai23@yahoo.com')
                    && (docItem.data().email !== 'virajnmerai16@gmail.com')
                    && (docItem.data().email !== 'parth@gmail.com')
                    && (docItem.data().name !== 'test')
                    && (docItem.data().name !== 'Ritika')
                    && (docItem.data().name !== 'Gayatri')
                    && (docItem.data().name !== 'xxxx')
                    && (docItem.data().name !== 'Jigar Bhavsar')
                    && (docItem.data().name !== 'Ashish')
                    && (docItem.data().name !== 'ashish')
                    && (docItem.data().name !== 'ashish Kanani')
                    && (docItem.data().name !== 'Alvin')
                    && (docItem.data().name !== 'kathan')
                    && (docItem.data().name !== 'Kathan')
                    && (docItem.data().name !== 'Simran')
                    // && (docItem.data().mobilenumber !== '9537262686')

                ) {
                    let docData = docItem.data();
                    console.log('country', docData);
                    docData._id = docItem.id;

                    allEntry.push(docData);
                    // if (isSceneVisitPage) {
                    //     if (docData.fullname !== 'undefined'
                    //         && docData.useremail !== 'undefined'
                    //         && docData.mobilenumber !== 'undefined'
                    //         && docData.fullname !== null
                    //         && docData.useremail !== null
                    //         && docData.mobilenumber !== null) {

                    //         allEntry.push(docData);
                    //     }
                    // } else {
                    //     if (docData.fullname !== 'undefined'
                    //         && docData.useremail !== 'undefined'
                    //         && docData.mobilenumber !== 'undefined'
                    //         && docData.fullname !== null
                    //         && docData.useremail !== null
                    //         && docData.mobilenumber !== null) {

                    //         allEntry.push(docData);
                    //     }
                    // }
                }
            });
            resolve(allEntry);
        }).catch(error => {
            reject(error);
        });
    });

    DatabaseQuery.then(result => {
        callBackFunction(result);
    }).catch(error => {
        console.error('Database query error for ' + collectionName + ' is', error);
    });
}


/**
 * This function will output array of database query in callback function
 * for asked collectionName in their respective components
 * 
 * @param {Asked collection name} collectionName 
 * @param {Data array returning function} callBackFunction 
 */
export function getExpoImageClickAnalytics(collectionName, callBackFunction, isSceneVisitPage = false) {
    // let userPid = localStorage.getItem('project-id');
    // let userrole = localStorage.getItem('user-role');
    const firestore = firebase.firestore();
    let items = firestore.collection(collectionName);
    items = items.where("expo", "==", 'medex2022')
    // if (userrole !== 'admin') {
    //     items = items.where("projectid", "==", userPid)
    // }

    let DatabaseQuery = new Promise((resolve, reject) => {
        items.get().then(function (itemSnapshot) {
            let allEntry = [];
            itemSnapshot.forEach(function (docItem) {
                if ((docItem.data().mobilenumber !== '9375705497')
                    && (docItem.data().mobilenumber !== '8347763858')
                    && (docItem.data().mobilenumber !== '8766455861')
                    && (docItem.data().mobilenumber !== '8401835825')
                    && (docItem.data().mobilenumber !== '9016641369')
                    && (docItem.data().mobilenumber !== '08866670336')
                    && (docItem.data().mobilenumber !== '7621901935')
                    && (docItem.data().mobilenumber !== '9537262686')
                    && (docItem.data().projectname !== 'Angels')
                    && (docItem.data().projectname !== 'Webinar Hall')
                    && (!docItem.data().link.includes('expo.virtualpropexpo2020.com'))
                ) {
                    let docData = docItem.data();
                    docData._id = docItem.id;

                    if (docData.fullname !== 'undefined'
                        && docData.useremail !== 'undefined'
                        && docData.mobilenumber !== 'undefined'
                        && docData.fullname !== null
                        && docData.useremail !== null
                        && docData.mobilenumber !== null) {

                        allEntry.push(docData);
                    }
                }
            });
            resolve(allEntry);
        }).catch(error => {
            reject(error);
        });
    });

    DatabaseQuery.then(result => {
        callBackFunction(result);
    }).catch(error => {
        console.error('Database query error for ' + collectionName + ' is', error);
    });
}

/**
 * This function will output array of database query in callback function
 * for asked collectionName in their respective components
 * This is for userlogs and feedback component only because
 * these two component collection has field name as mobileno instead of mobilenumber
 * 
 * @param {Asked collection name} collectionName 
 * @param {Data array returning function} callBackFunction 
 */
export function getPageDataSecondary(collectionName, callBackFunction, isFeedbackPage = false) {
    let userPid = localStorage.getItem('project-id');
    let userrole = localStorage.getItem('user-role');
    const firestore = firebase.firestore();
    let items = firestore.collection(collectionName);
    items = items.where("expo", "==", expoName)
    if (userrole !== 'admin') {
        items = items.where("projectid", "==", userPid)
    }

    let DatabaseQuery = new Promise((resolve, reject) => {
        items.get().then(function (itemSnapshot) {
            let allEntry = [];
            itemSnapshot.forEach(function (docItem) {
                if ((docItem.data().mobileno !== '9375705497')
                    && (docItem.data().mobileno !== '9979981349')
                    && (docItem.data().mobileno !== '+917984604061')
                    && (docItem.data().mobileno !== '9016641369')
                    && (docItem.data().mobileno !== '9924302720')
                    && (docItem.data().mobileno !== '7383810472')
                    && (docItem.data().mobileno !== '1234567891')
                    && (docItem.data().mobileno !== '1234568791')
                    && (docItem.data().mobileno !== '7048853622')
                    && (docItem.data().mobileno !== '9662552666')
                    && (docItem.data().mobileno !== '7621901935')
                    && (docItem.data().mobileno !== '7984604061')
                    && (docItem.data().mobileno !== '9664777053')
                    && (docItem.data().mobileno !== '9016037231')
                ) {
                    let docData = docItem.data();
                    docData._id = docItem.id;

                    if (isFeedbackPage) {
                        if (docData.username !== ''
                            && docData.mobileno !== ''
                            && docData.star !== ''
                            && docData.username !== 'undefined'
                            && docData.mobileno !== 'undefined'
                            && docData.star !== 'undefined'
                            && docData.username !== null
                            && docData.mobileno !== null
                            && docData.star !== null) {

                            allEntry.push(docData);
                        }
                    } else {
                        if (docData.mobileno !== 'undefined'
                            && docData.username !== 'undefined'
                            && docData.mobileno !== null
                            && docData.username !== null
                            && docData.mobileno !== ' '
                            && docData.username !== ' ') {

                            allEntry.push(docData);
                        }
                    }
                }
            });
            resolve(allEntry);
        }).catch(error => {
            reject(error);
        });
    });

    DatabaseQuery.then(result => {
        callBackFunction(result);
    }).catch(error => {
        console.error('Database query error for ' + collectionName + ' is', error);
    });
}
