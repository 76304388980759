/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { MDBDataTable } from 'mdbreact';

import "react-datepicker/dist/react-datepicker.css";
import firebase from './firebase';
import { Form, FormControl, Button } from 'react-bootstrap';
import { getExpoImageClickAnalytics } from './firebase-function';

let fromDate;
let toDate;
let finalFromD;
let finalToD;
let fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
let fileExtension = '.xlsx';

// const $ = require( "jquery" )( window );
export default class VideoClick extends React.Component {

    static data = [];
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            startDateFrom: '',
            startDateTO: '',
            // customers: this.customers(),
            // startDateFrom: new Date(),
            // startDateTO: new Date()
        }
        this.filterName = this.filterName.bind(this);
    }
    componentDidMount() {

        // let userPid = localStorage.getItem('project-id');

        // this.get_users_list();
        getExpoImageClickAnalytics('ExpoimageClick', this.manageMyTable)
    }

    manageMyTable = (databaseEntries) => {
        let formattedData = [];
        for (let i = 0; i < databaseEntries.length; i++) {
            let regist = {
                username: databaseEntries[i].username,
                projectname: databaseEntries[i].projectname,
                mobilenumber: databaseEntries[i].mobilenumber,
                email: databaseEntries[i].email,
                link: databaseEntries[i].link,
                time: new Date(databaseEntries[i].date).toLocaleString('en-IN'),
            }
            formattedData.push(regist);
        }
        this.get_users_list(formattedData);
    }

    get_users_list = (myArrayData) => {
        let mydata = {
            columns: [
                {
                    label: 'Fullname',
                    field: 'username',
                    sort: 'asc',
                    width: 180
                },
                {
                    label: 'Project Name',
                    field: 'projectname',
                    sort: 'asc',
                    width: 180
                },
                {
                    label: 'Mobileno',
                    field: 'mobilenumber',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 200
                },

                {
                    label: 'Time',
                    field: 'time',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Link',
                    field: 'link',
                    sort: 'asc',
                    width: 250
                },
            ],
            rows: myArrayData,
        };
        // var sortArray = data.sort((a, b) => b.filterTime - a.filterTime);
        this.setState({ data: mydata, data2: mydata });

    }



    filterName(event) {
        const tempData = this.filterByValue(this.state.data.rows, event.target.value);
        // console.log("MY ALl DATA :: ",tempData)
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }
    filterByValue(array, value) {
        return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    handleChange = date => {
        this.setState({
            startDateFrom: date
        });
    };

    handleChangeTo = date => {
        this.setState({
            startDateTO: date
        });
    };

    handleFilter = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value;
        toDate = document.getElementById('toDate').value;
        // const  pikerDate = document.getElementById('datepicker').value;
        // console.log("pikerDate :: ",pikerDate)


        if (fromDate === "") {
            alert("Please enter From date")
        } else if (toDate === "") {
            alert("Please enter To date")
        } else {
            // console.log("All :: ",this.state.data.rows)
            // let tempData = []
            let myFromDate = fromDate.split('/');
            let myFromDD = parseInt(myFromDate[0]);
            let myFromMM = parseInt(myFromDate[1]);
            let myFromYY = parseInt(myFromDate[2]);
            finalFromD = myFromDD + "/" + myFromMM + "/" + myFromYY

            let myToDate = toDate.split('/');
            let myToDD = parseInt(myToDate[0]);
            let myToMM = parseInt(myToDate[1]);
            let myToYY = parseInt(myToDate[2]);
            finalToD = myToDD + "/" + myToMM + "/" + myToYY
            // console.log("DATE OF FROM :: ",finalFromD)
            // console.log("DATE OF TO :: ",finalToD)

            let tempData = this.filterByValueDate(this.state.data.rows);
            // this.state.data2.rows = tempData
            // console.log("mydata  Convert Data :: ",this.state.data.rows)
            let mydata = {
                columns: this.state.data.columns,
                rows: tempData
            }



            this.setState({
                data: mydata
            });

        }
    }

    filterByValueDate(array) {


        return array.filter((item) =>
            // console.log("item.filterTime :: ",new Date(finalFromD).getTime() + "===" + item.filterTime)

            item.filterTime >= new Date(finalFromD).getTime() && item.filterTime <= new Date(finalToD).getTime()
            // new Date(item.date) >= new Date(finalFromD) && new Date(item.date) <= new Date(finalToD)
        );


    }

    handleCancle = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value = "";
        toDate = document.getElementById('toDate').value = "";
        this.setState({
            startDateFrom: '',
            startDateTO: ''
        })
        this.componentDidMount()
    }


    render() {

        return (
            <div>
                <div className="flexheading margintopflex">
                    <h3 className="fontweight fontfamily">Video Analytics</h3>
                    <div className="flexheading">
                        {/* {localStorage.getItem('user-role') === 'admin' &&
                            <label className="countlabel marginrightlabel"> Count :  <label className="numbers">{this.state.data.length}</label></label>
                        } */}
                        <FormControl type="text" placeholder="search" onChange={(e) => this.filterName(e)} className="mr-sm-2 navbar-search-prop" />

                    </div>
                </div>
                <div className="flexheading margintopflex">

                    <Form inline className=" marginbottomform">
                        {/* <DatePicker
                            id="fromDate"
                            selected={this.state.startDateFrom}
                            onChange={this.handleChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            className="marginrightdate navbar-search-prop"
                        />
                        <DatePicker
                            id="toDate"
                            selected={this.state.startDateTO}
                            onChange={this.handleChangeTo}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            className="marginrightdate navbar-search-prop"

                        />
                        
                        <div className="flexrow">
                            <button onClick={this.handleFilter} className="marginrightdate dashboardbtn">
                                Filter
                        </button>
                            <button onClick={this.handleCancle} className="marginrightdate dashboardbtn">
                                CANCEL
                        </button>
                        </div> */}
                        <div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button  downloadbtn"
                                table="table-to-xls"
                                filename="MEDEX 2022 VideoAnalytics Visit"
                                sheet="MEDEX 2022 VideoAnalytics Visit"
                                buttonText="Download as XLS" />
                        </div>
                    </Form>
                </div>
                <div className="overflowtableanalysis">

                    <MDBDataTable
                        paging={true}
                        searching={false}
                        data={this.state.data2}
                    // lengthMenu = {[[10, 25, 50, -1], [10, 25, 50, "All"]]}
                    />

                </div>

                <div className="overflowtableanalysis" style={{ display: 'none' }}>
                    <table id="table-to-xls" className="table table-hover margintoptable">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Fullname</th>
                                <th scope="col">Project Name</th>
                                <th scope="col">Mobileno</th>
                                <th scope="col">Email</th>
                                <th scope="col">Link</th>
                                <th scope="col">Time</th>

                            </tr>
                        </thead>
                        {this.state.data2.rows &&
                            <tbody>
                                {Object.values(this.state.data2.rows).map((users, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{users.username}</td>
                                            <td>{users.projectname}</td>
                                            <td>{users.mobilenumber}</td>
                                            <td>{users.email}</td>
                                            <td>{users.link}</td>
                                            <td>{users.time}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }
}