export const videoList = [
    {
        name: "welcome_video",
        videoName: "Welcome video from course faculty",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 172
    },
    {
        name: "M-01",
        videoName: "Diagnosis and Classifications of Heart Failure",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 2044
    },
    {
        name: "M-02",
        videoName: "Pathophysiology of Heart Failure and Classifications / Causes",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1133
    },
    {
        name: "M-03",
        videoName: `Course of Clinical Heart Failure`,
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 999
    },
    {
        name: "M-04",
        videoName: "Heart Failure Epidemiology & Outcomes: Morbidity, Mortality and Quality of Life",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 954
    },
    {
        name: "M-05",
        videoName: "Heart Failure Serial Evaluation, Frequency of Visits and Risk Scoring",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1163
    },
    {
        name: "M-06",
        videoName: "Treatment Recommendations for Stage A and B HFrEF (HF with Reduced Ejection Fraction)",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 753
    },
    {
        name: "M-07",
        videoName: "Treatment Recommendations for Stage C and D HFrEF - (Core 4 Therapies)",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1682
    },
    {
        name: "M-08",
        videoName: "Treatment Recommendations for Stage C and D HFrEF (Additional Therapies- Medications & Devices)",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1446
    },
    {
        name: "M-09",
        videoName: "Diuretic Therapy for Symptomatic and Worsening Heart Failure due to Hypervolemia",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 785
    },
    {
        name: "M-10",
        videoName: "Medications in HFrEF - Caveats in Use based on Potassium Level, Blood Pressure, Renal Function",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1343
    },
    {
        name: "M-11",
        videoName: "Treatment Recommendations for HFmrEF and HFpEF",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 424
    },
    {
        name: "M-12",
        videoName: "Non-Pharmacologic Management of Heart Failure",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1297
    },
    {
        name: "M-13",
        videoName: "Goals for Hospital Care of HF and Pre-Discharge Assessment",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1139
    },
    {
        name: "M-14",
        videoName: "Transitions of Care",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 1160
    },
    {
        name: "M-15",
        videoName: "Key Takeaways Module 15",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 961
    },
    {
        name: "M-16",
        videoName: "Key Takeaways Module 16",
        speakerName: "Prof. Nancy M. Alert",
        videoDuration: 42
    }
];