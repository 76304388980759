/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from 'react';
// import ReactDOM from 'react-dom';
import firebase from './firebase';
import { Link } from "react-router-dom";
import melzologo from './assets/images/logo.png';
import angleslogo from './assets/images/medex logo.png';
import boehringerlogo from './assets/images/BOEHRINGERLOGO.png';
import './App.css';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { AuthContext } from './AuthContext'
import { faBorderNone } from '@fortawesome/free-solid-svg-icons';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';

let txtuseruid;


class App extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      // email: '',
      // password: '',
      regdialog: false
      // redirect: false

    }

    // this.onSubmitLogin = this.onSubmitLogin.bind(this);
    this.labelReg = this.labelReg.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
  }

  componentDidMount() {
    // const signUpButton = document.getElementById('signUp');

    // const signInButton = document.getElementById('login');
    // const container = document.getElementById('container');

    // signUpButton.addEventListener('click', () => container.classList.add('right-panel-active'));


    // signInButton.addEventListener('click', () => container.classList.remove('right-panel-active'));

  }

  handleRegister = (e) => {
    e.preventDefault();

    const Signupemail = document.getElementById('Signupemail').value;
    const Signuppassword = document.getElementById('Signuppassword').value;
    var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;
    let tempNum = '';

    if (Signupemail === "") {
      alert("Please enter a valid Email-id");
    } else if (Signuppassword === "") {
      alert("Please enter a valid Password");

    } else {
      if (!patternEmail.test(Signupemail)) {
        alert("Please enter a valid Email-id");
      } else {
        console.log("Email :: ", Signupemail);

        firebase
          .auth()
          .createUserWithEmailAndPassword(Signupemail, Signuppassword)
          .then(() => {
            // console.log("Success Data Store");
            // alert("yes registerd Checked it")
            document.getElementById('reg-button').disabled = true;
            const firestore = firebase.firestore();
            let docRef = firestore.collection("AnalyticsUser")
            docRef = docRef.where("email", "==", Signupemail)
            docRef = docRef.where("expo", "==", "angels")
            docRef.get().then(function (doc) {
              if (!doc.empty) {
                console.log("IFF")
                tempNum = 1;
                this.context.login(true);
              } else {

                const auth = firebase.auth();
                auth.signOut().then((result) => {
                  window.location.href = "/";
                  alert("you are not authorised for register");
                }).catch((error) => {
                });
              }
            })


          })
          .catch((error) => {
            console.log("Error: ", error)
            if (tempNum !== 1) {

              alert("Email-id already used");

            }

          });


        // const firestore = firebase.firestore();
        // const docRef = firestore.collection("AnalyticsUser").where("email", "==", Signupemail);
        // docRef.get().then(function (doc) {
        //   if (!doc.empty) {
        //     console.log("IF")
        //     firebase
        //       .auth()
        //       .createUserWithEmailAndPassword(Signupemail, Signuppassword)
        //       .then(() => {
        //         // console.log("Success Data Store");
        //         // alert("yes registerd Checked it")

        //         //----LODER CALL---
        //         document.getElementById('reg-button').disabled = true;
        //         document.getElementById('login-name').style.display = 'block';
        //         document.getElementById('btn-loader').style.display = 'none';
        //         //--------------------
        //         tempNum = 1;

        //         // this.context.login(true);

        //       })
        //       .catch((error) => {
        //         console.log("Error: ", error)
        //         if (tempNum !== 1) {
        //           alert("Email-id already used")
        //         }

        //       });

        //   } else {
        //     console.log("ELSE")
        //     alert("you are not authorised for register");
        //   }
        // })

      }
    }
    // this.setState({ regdialog: false })
  }

  //---------------------------REG DIALOG-------------------------
  labelReg(e) {
    e.preventDefault();
    this.setState({
      regdialog: true
    })
  }

  handleClose = () => {
    this.setState({ regdialog: false })
  }

  onSubmitLogin = (e) => {
    e.preventDefault();

    //----LODER CALL---
    document.getElementById("reg-button").removeAttribute('disabled');
    document.getElementById('login-name').style.display = 'none';
    document.getElementById('btn-loader').style.display = 'block';
    //--------------------

    const loginEmail = document.getElementById('loginEmail').value;
    const loginPassword = document.getElementById('loginPassword').value;
    var patternEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$/;


    if (loginEmail === "") {
      alert("Please enter a valid Email-id");
      //----LODER CALL---
      document.getElementById('reg-button').disabled = true;
      document.getElementById('login-name').style.display = 'block';
      document.getElementById('btn-loader').style.display = 'none';
      //--------------------
    } else if (loginPassword === "") {
      alert("Please enter a valid Password")
      //----LODER CALL---
      document.getElementById('reg-button').disabled = true;
      document.getElementById('login-name').style.display = 'block';
      document.getElementById('btn-loader').style.display = 'none';
      //--------------------
    } else {
      if (!patternEmail.test(loginEmail)) {
        alert("Please enter a valid Email-id");
        //----LODER CALL---
        document.getElementById('reg-button').disabled = true;
        document.getElementById('login-name').style.display = 'block';
        document.getElementById('btn-loader').style.display = 'none';
        //--------------------
      } else {
        const firestore = firebase.firestore();
        let docRef = firestore.collection("AnalyticsUser")
        docRef = docRef.where("email", "==", loginEmail)
        docRef = docRef.where("expo", "==", "medex2022")

        docRef.get().then(function (doc) {
          if (!doc.empty) {
            //----LODER CALL---
            document.getElementById('reg-button').disabled = true;
            document.getElementById('login-name').style.display = 'block';
            document.getElementById('btn-loader').style.display = 'none';
            //--------------------  
            firebase.auth().signInWithEmailAndPassword(loginEmail, loginPassword)
              .then(() => {

              })
              .catch((error) => {
                // console.log("Error :: ", error.code)
                // console.log("Error :: ", error.message)
                // console.log("TEMP NO :: ", tempNumLog)
                // if (tempNumLog !== 1) {
                alert("Please Enter Valid Email or Password");
                // }

              })

            let tempNumLog;
            docRef.get().then(function (querySnapshot) {
              querySnapshot.forEach(function (docer) {

                // console.log(docer.id, " => ", docer.data());
                console.log(docer.data());
                tempNumLog = 1;
                let userData = docer.data();
                let userEmail = userData.email;
                let userPid = userData.projectid;
                let userRole = userData.role;
                localStorage.setItem('project-id', userPid);
                localStorage.setItem('user-name', userEmail);
                localStorage.setItem('user-role', userRole);
                // this.setState({ regdialog: false })
                this.context.login(true);
                // console.log("My Context :: ", this.context.login(true));
                console.log("My Context");

              });
            })
              .catch(function (error) {
                console.log("Error getting documents: ", error);
                if (tempNumLog !== 1) {
                  // alert("Please Enter Valid Email or Password In ");
                }
              });

          } else {
            //----LODER CALL---
            document.getElementById('reg-button').disabled = true;
            document.getElementById('login-name').style.display = 'block';
            document.getElementById('btn-loader').style.display = 'none';
            //--------------------  
            alert("Please Register First");

          }
        })


      }
    }

  }




  render() {
    // const { email, password } = this.state;
    // const { redirect } = this.state
    // if(redirect) {
    //   return <Address />
    // }

    return (
      <div className="wrapper">
        <div className="clip">
          <div className="bg" >
          </div>
          <div className="bg-span"></div>
        </div>
        {/* angles logo */}
        <div className="logopositionleft">
          <div className="flexlogo">
            <img src={angleslogo} className="widthangleslogo" />
          </div>
        </div>
        {/* boehringer logo */}

        <div className="logopositionright">
          <div className="flexlogo">
            <img src={boehringerlogo} className="widthboihringerlogo" />
          </div>
        </div>

        <div className="container2">
          <div className="row">
            <div className="col-lg-3 col-md-2"></div>
            <div className="col-lg-6 col-md-8 login-box">

              <div className="col-lg-12 login-title">
                ADMIN PANEL
              </div>

              {/* <div className="col-lg-12 login-form">
                <div className="col-lg-12 login-form">
                  <form> */}

              <input id="loginEmail" type="email" className="inputloginpage" placeholder="EMAIL ID" />
              <input id="loginPassword" type="password" className=" inputloginpage" placeholder="PASSWORD" />

              <div className="col-lg-12 loginbttm">
                {/* <div className="col-lg-6 login-btm login-text">
                      </div> */}
                <div className="col-lg-12 login-btm login-button">
                {/* <a className="registerbutton" onclick="gotoagenda()" style="width: 230px;">
                                        Event Agenda

                                    </a> */}
                  <button id="reg-button" type="submit" className="registerbutton" onClick={this.onSubmitLogin}>
                    <div style={{ display: 'block' }} id="login-name">Login</div>
                    <div className="loaderloginbtn" id="btn-loader" style={{ display: 'none' }} />
                  </button>
                </div>
              </div>
              {/* <h6> Create an account? &nbsp;
                      <span className="underline" onClick={this.labelReg}>REGISTER
                      </span>
                    </h6> */}
              {/* <div className="col-lg-12 login-title" onClick={this.labelReg}>
                      Register
                    </div> */}

              {/* </form>
                </div>
              </div> */}
              <div className="col-lg-3 col-md-2"></div>


            </div>
          </div>
        </div>


        {/* <div className="footer" id="Footer">
          <footer className="footer_area">
            <p className="footer-text">Powered by
                   <a href="https://melzo.com/" target="_blank" className="melzotext">melzo.com</a></p>
          </footer>
        </div> */}


        <div>
          <Dialog open={this.state.regdialog} onClose={this.handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" >Register</DialogTitle>
            <DialogContent>
              <TextField
                // value={email}
                autoFocus
                margin="dense"
                id="Signupemail"
                label="Email"
                type="email"
                fullWidth
              />
              <TextField
                // value={password}
                // autoFocus
                margin="dense"
                id="Signuppassword"
                label="Password"
                type="password"
                fullWidth
              />
            </DialogContent>
            <DialogContent>
              <button type="submit" className="submitbtn" onClick={this.handleRegister} >
                Register
              </button>
              <button onClick={this.handleClose} type="submit" className="submitbtn marginrightbtn">
                Cancel
              </button>

              {/* <Button color="primary">
                CANCEL
              </Button>
              <Button onClick={this.handleOpen} color="primary">
                SIGN UP
              </Button> */}
            </DialogContent>
          </Dialog>
        </div>


      </div>


    );
  }
}
export default App;