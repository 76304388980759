/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react';
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { MDBDataTable } from 'mdbreact';

import "react-datepicker/dist/react-datepicker.css";
import firebase from './firebase';
import { Form, FormControl, Button } from 'react-bootstrap';
import { getCurrentPageAnalytics } from './firebase-function';
import moment from 'moment'

let fromDate;
let toDate;
let toHHMMSS;
let typeValue;

export default class WebinarHallExport extends React.Component {
    static data = [];
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            data2: [],
            dataUser: [],
            startDateFrom: '',
            startDateTO: '',
            totalvisitor: '',
        }
        this.filterName = this.filterName.bind(this);
    }
    componentDidMount() {
        getCurrentPageAnalytics('MEDEX2022LiveUsers', this.manageMyTable);
    }

    getCountOfCountryVisitors = () => {
        const firestore = firebase.firestore();
        let DatabaseQuery = new Promise((resolve, reject) => {
            firestore.collectionGroup('MEDEX2022LiveUsers').where('expo', '==', 'medex2022').get().then(function (itemSnapshot) {
                let allEntry = [];
                itemSnapshot.forEach(function (docItem) {
                    if ((docItem.data().mobilenumber !== '9375705497')
                        && (docItem.data().mobilenumber !== '8347763858')
                        && (docItem.data().mobilenumber !== '8766455861')
                        && (docItem.data().mobilenumber !== '8401835825')
                        && (docItem.data().mobilenumber !== '9016641369')
                        && (docItem.data().mobilenumber !== '08866670336')
                        && (docItem.data().mobilenumber !== '7621901935')
                        && (docItem.data().mobilenumber !== '8160968264')
                        && (docItem.data().email !== 'hevan@shilpmis.com')
                        && (docItem.data().email !== 'mohit@shilpmis.com')
                    ) {
                        let docData = docItem.data();
                        docData._id = docItem.id;

                        allEntry.push(docData);
                    }
                });
                resolve(allEntry);
            }).catch(error => {
                reject(error);
            });
        });

        DatabaseQuery.then(result => {
            console.log('All Data:::', result)
            callBackFunction(result);
        }).catch(error => {
            console.error('Database query error is', error);
        });
    }


    manageMyTable = (databaseEntries) => {
        console.log("DATA :: ", databaseEntries)
        let formattedData = [];
        for (let i = 0; i < databaseEntries.length; i++) {
            let endDate;
            if (databaseEntries[i].endTime === "") {
                let addSec = databaseEntries[i].startTime + 30000;
                let addedTime = new Date(addSec).toLocaleString('en-IN');
                endDate = addedTime
            } else {
                endDate = new Date(databaseEntries[i].endTime).toLocaleString('en-IN');
            }

            let startDate;
            if (databaseEntries[i].startTime === "") {
                startDate = "-"
            } else {
                startDate = new Date(databaseEntries[i].startTime).toLocaleString('en-IN');
            }
            let mobNumber;
            if (databaseEntries[i].mobileNumber === "") {
                mobNumber = "-"
            } else {
                mobNumber = databaseEntries[i].mobileNumber
            }

            let diff = Math.abs(new Date(databaseEntries[i].endTime) - new Date(databaseEntries[i].startTime));
            let different = (Math.abs(new Date(databaseEntries[i].endTime) - new Date(databaseEntries[i].startTime)) / 1000 / 60);
            let ms = diff % 1000;
            diff = (diff - ms) / 1000
            let ss = diff % 60;
            diff = (diff - ss) / 60
            let mm = diff % 60;
            let minutes = different;
            diff = (diff - mm) / 60
            let hh = diff % 24;
            let days = (diff - hh) / 24

            if (hh < 10) {
                hh = '0' + hh;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }
            if (ss < 10) {
                ss = '0' + ss;
            }

            if (days === 0) {
                diff = hh + ":" + mm + ":" + ss;
            }
            else {
                diff = days + "/" + hh + ":" + mm + ":" + ss;
            }

            if (databaseEntries[i].endTime === "") {
                diff = "00:00:30";
            }

            let userType;
            if (diff <= "00:00:30") {
                userType = 'Regular'
            } else {
                userType = 'Active'
            }

            if (databaseEntries[i].roomName === 'webinarHall') {
                typeValue = 'Webinar-hall'
            }

            let CompleteData = {
                id: databaseEntries[i]._id,
                fullname: databaseEntries[i].name,
                useremail: databaseEntries[i].email,
                mobilenumber: mobNumber,
                country: databaseEntries[i].countryName,
                designation: databaseEntries[i].designation,
                speciality: databaseEntries[i].speciality,
                starttime: startDate,
                endtime: endDate,
                duration: diff,
                usertype: userType,
                filterTime: databaseEntries[i].startTime,
                myfilterstartdate: new Date(databaseEntries[i].startTime).toLocaleDateString()
            }

            if (databaseEntries[i].roomName === 'webinarHall') {
                if (databaseEntries[i].email !== 'taycoon6@hotmail.com'
                    && databaseEntries[i].email !== 'kathandjv@gmail.com'
                    && databaseEntries[i].email !== 'jigar@smart-sonix.com'
                    && databaseEntries[i].email !== 'ritikadesai14@gmail.com'
                    && databaseEntries[i].email !== 'mariam.ouda@boehringer-ingelheim.com'
                    && days === 0
                ) {

                    formattedData.push(CompleteData)
                }
            }
        }
        this.formatTable(formattedData);
    }

    formatTable = (myArrayData) => {
        var sortArray = myArrayData.sort(function (a, b) { return b.filterTime - a.filterTime });
        let mydata = {
            columns: [
                {
                    label: 'User Name',
                    field: 'fullname',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Email',
                    field: 'useremail',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Mobile Number',
                    field: 'mobilenumber',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Country',
                    field: 'countryName',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Designation',
                    field: 'designation',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Speciality',
                    field: 'speciality',
                    sort: 'asc',
                    width: 150
                },

                {
                    label: 'Start-time',
                    field: 'starttime',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'End-time',
                    field: 'endtime',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Duration',
                    field: 'duration',
                    sort: 'asc',
                    width: 150
                },

            ],
            rows: sortArray,
        };
        this.setState({ data: mydata, data2: mydata });
    }

    filterName(event) {
        // const tempData = this.filterByValue(this.state.data.rows, event.target.value);

        const tempData = this.filterByValue(this.state.data.rows, event.target.value);

        let sortArray = tempData.sortBy('email')

        var result = [];
        var d = '0:0:0';
        let demoData = sortArray.reduce(function (res, value) {
            if (!res[value.useremail]) {
                d = '0:0:0';
                res[value.useremail] = {
                    useremail: value.useremail,
                    duration: '',
                    fullname: value.fullname,
                    designation: value.designation,
                    speciality: value.speciality,
                    countryName: value.country,
                    mobilenumber: value.mobilenumber,
                    starttime: value.starttime,
                    endtime: value.endtime,
                };
                result.push(res[value.useremail])
            }
            d = moment.duration(d).add(moment.duration(value.duration))
            res[value.useremail].duration = moment.utc(d.as('milliseconds')).format("HH:mm:ss")
            return res;
        }, []);

        let finalDemoData = []
        for (var key in demoData) {
            finalDemoData.push(demoData[key]);
        }

        let mydata = {
            columns: this.state.data.columns,
            rows: finalDemoData
        }
        this.setState({
            data2: mydata
        });
        // let mydata = {
        //     columns: this.state.data.columns,
        //     rows: tempData
        // }
        // this.setState({
        //     data2: mydata
        // });
    }
    filterByValue(array, value) {
        return array.filter((data) => JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    handleChange = date => {
        this.setState({
            startDateFrom: date
        });
    };

    handleChangeTo = date => {
        this.setState({
            startDateTO: date
        });
    };

    handleFilter = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value;
        toDate = document.getElementById('toDate').value;

        if (fromDate === "") {
            alert("Please enter From date")
        } else if (toDate === "") {
            alert("Please enter To date")
        } else {
            const tempData = this.filterByValueDate(this.state.data);
            this.setState({
                data2: tempData
            });

        }
    }

    filterByValueDate(array) {

        return array.filter((item) =>

            item.starttime >= fromDate && item.starttime <= toDate
        );

    }

    handleCancle = (e) => {
        e.preventDefault();
        fromDate = document.getElementById('fromDate').value = "";
        toDate = document.getElementById('toDate').value = "";
        this.setState({
            startDateFrom: '',
            startDateTO: ''
        })
        this.componentDidMount()
    }

    firstDate = (e) => {
        e.preventDefault();
        let tmpdate = '26/1/2021'
        const tempData = this.filterByValue(this.state.data.rows, tmpdate);

        let sortArray = tempData.sortBy('email')

        var result = [];
        var d = '0:0:0';
        let demoData = sortArray.reduce(function (res, value) {
            if (!res[value.useremail]) {
                d = '0:0:0';
                res[value.useremail] = {
                    useremail: value.useremail,
                    duration: '',
                    fullname: value.fullname,
                    designation: value.designation,
                    speciality: value.speciality,
                    countryName: value.country,
                    mobilenumber: value.mobilenumber,
                    starttime: value.starttime,
                    endtime: value.endtime,
                };
                result.push(res[value.useremail])
            }
            d = moment.duration(d).add(moment.duration(value.duration))
            res[value.useremail].duration = moment.utc(d.as('milliseconds')).format("HH:mm:ss")
            return res;
        }, []);

        let finalDemoData = []
        for (var key in demoData) {
            finalDemoData.push(demoData[key]);
        }

        let mydata = {
            columns: this.state.data.columns,
            rows: finalDemoData
        }
        this.setState({
            data2: mydata
        });
    }


    allData = (e) => {
        e.preventDefault();
        const tempData = this.filterByValue(this.state.data.rows, '');
        let mydata = {
            columns: this.state.data.columns,
            rows: tempData
        }
        this.setState({
            data2: mydata
        });
    }

    render() {
        return (
            <div>
                <div className="flexheading">
                    <h3 className="fontweight fontfamily">Webinar Hall</h3>
                    <div className="flexheading">
                        <FormControl type="text" placeholder="search" onChange={(e) => this.filterName(e)} className="mr-sm-2 navbar-search-prop" />

                    </div>
                </div>
                <button className="registerbutton" onClick={this.allData} >
                    All
                </button>
                <div class="schedule-tab">
                    <div class="schedule-tab__item " data-tab="tab-1" onClick={this.firstDate} >
                        <h2>Day 01</h2>
                        <p>22/01/21</p>
                    </div>
                    
                </div>
                <div className="flexheading">

                    <Form inline className=" marginbottomform">

                        <div>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button  downloadbtn"
                                table="table-to-xls"
                                filename="MEDEX 2022 Webinar Hall Attendee"
                                sheet="MEDEX 2022 Webinar Hall"
                                buttonText="Download as XLS" />
                        </div>
                    </Form>
                </div>
                <div className="overflowtableanalysis">

                    <MDBDataTable
                        searching={false}
                        paging={true}
                        data={this.state.data2}
                    />

                </div>
                <div className="overflowtableanalysis" style={{ display: 'none' }}>
                    <table id="table-to-xls" className="table table-hover margintoptable">
                        <thead>
                            <tr>
                                <th scope="col">Sr. No.</th>
                                <th scope="col">User Name</th>
                                <th scope="col">Country</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Email</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Speciality</th>
                                <th scope="col">Start-time</th>
                                <th scope="col">End-time</th>
                                <th scope="col">Total Duration</th>

                            </tr>
                        </thead>
                        {this.state.data2.rows &&
                            <tbody>
                                {Object.values(this.state.data2.rows).map((users, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{users.fullname}</td>
                                            <td>{users.countryName}</td>
                                            <td>{users.mobilenumber}</td>
                                            <td>{users.useremail}</td>
                                            <td>{users.designation}</td>
                                            <td>{users.speciality}</td>
                                            <td>{users.starttime}</td>
                                            <td>{users.endtime}</td>
                                            <td>{users.duration}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }
}